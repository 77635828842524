<div class="row">
    <div class="col-md-4 ml-1">
      <h6><b>{{'COMMON_DOCUMENT_MENU.DOCUMENT_NAME' | translate}}</b></h6>
    </div>
    <div class="col-md-4">
      <h6><b>{{'COMMON_DOCUMENT_MENU.LINK' | translate}}</b></h6>
    </div>
    <div class="col-md-4">
      <h6><b>{{'COMMON_DOCUMENT_MENU.ATTACHMENT_DESCRIPTION' | translate}}</b></h6>
    </div>

  <div class="col-md-12 mt-3" *ngFor="let attachment of attachmentList; let i = index">
    <div class="att-list">
        <mat-card>
        <div class="row">
            <div class="col-md-3">
            <mat-card-content class="card-col">
                <img *ngIf="attachment.documentUrl.includes('pdf')" src="../../../../../assets/images/image-gallery/pdf-image.png" class="user_img" alt="User">
                <img *ngIf="attachment.documentUrl.includes('png')" src="../../../../../assets/images/image-gallery/png-image.png" class="user_img" alt="User">
                <img *ngIf="attachment.documentUrl.includes('jpg')" src="../../../../../assets/images/image-gallery/jpg-logo.png" class="user_img" alt="User">
                <img *ngIf="attachment.documentUrl == ''" src="../../../../../assets/images/image-gallery/pdf-image.png" class="user_img" alt="User">
                {{attachment.documentName}}
            </mat-card-content>
            </div>

            <div class="col-md-5">
            <mat-card-content class="card-col"><a  href="{{attachment?.documentUrl}}">{{attachment?.documentUrl}}</a></mat-card-content>
            </div>
            <div class="col-md-3">
            <mat-card-content>{{attachment.description}}</mat-card-content>
            </div>

        <mat-card-content class="col-md-1 card-col">
            <button class="tableInnerMenuBtn btn-container no-hover-effect mb-3" mat-button [matMenuTriggerFor]="menu"><mat-icon class="mb-3">more_vert</mat-icon></button>
            <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
            <a class="menu-custom-item" mat-menu-item href="{{attachment?.documentUrl}}"  [target]="'_blank'"><span class="menu-custom-span" ><mat-icon>download</mat-icon>&nbsp;{{'COMMON.DOWNLOAD' | translate}}</span></a>
            <button class="menu-custom-item" mat-menu-item (click)="removeAttachment(i)"><span class="menu-custom-span menu-custom-span-red" ><mat-icon>delete</mat-icon>&nbsp;{{'COMMON.DELETE' | translate}}</span></button>
            </mat-menu>
        </mat-card-content>
        </div>
        </mat-card>
    </div>

  </div>
</div>
