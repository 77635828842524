<div class="row">
    <div class="col-md-2 mt-2" *ngFor="let attachment of attachmentList; let i = index">
        <div class="att-grid">
            <mat-card>
              <div class="row container mt-2">
                <div class="col-md-9">
                    <mat-card-title>
                          <b>{{attachment.documentName}}</b>
                    </mat-card-title>
                    </div>
                  <div class="col-md-3">
                    <button class="tableInnerMenuBtn btn-container grid-more-btn" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
                        <a class="menu-custom-item" mat-menu-item href="{{attachment?.documentUrl}}"  [target]="'_blank'"><span class="menu-custom-span" ><mat-icon>download</mat-icon>&nbsp;{{'COMMON.DOWNLOAD' | translate}}</span></a>
                        <button class="menu-custom-item" mat-menu-item (click)="removeAttachment(i)"><span class="menu-custom-span menu-custom-span-red" ><mat-icon>delete</mat-icon>&nbsp;{{'COMMON.DELETE' | translate}}</span></button>
                    </mat-menu>
                  </div>
                </div>
                <div class="row mt-2 text-center">
                  <div class="col-md-12">
                    <img *ngIf="attachment.documentUrl.includes('pdf')" src="../../../../../assets/images/image-gallery/pdf-image.png" class="user_img" alt="User">
                    <img *ngIf="attachment.documentUrl == ''" src="../../../../../assets/images/image-gallery/pdf-image.png" class="user_img" alt="User">
                    <img *ngIf="attachment.documentUrl.includes('png')" src="../../../../../assets/images/image-gallery/png-image.png" class="user_img" alt="User">
                    <img *ngIf="attachment.documentUrl.includes('jpg')" src="../../../../../assets/images/image-gallery/jpg-logo.png" class="user_img" alt="User">
                  </div>
                </div>

              <div class="row text-center">
                <div class="col-md-12">
                  <mat-card-content class="card-col"><b>{{attachment.documentUrl?.split('crm/')[1]}}</b></mat-card-content>
                </div>
              </div>
              <!-- <div class="row">
                <mat-card-content class="text-center"><b>{{attachment.documentUrl?.split('crm/')[1]}}</b></mat-card-content>
                <div class="col-md-12 body-txt-right">
                  <mat-card-content class="p-0">{{'MANAGE_ATTACHMENT.CATEGORY' | translate}} : </mat-card-content>
                  <mat-card-content>{{'COMMON_DOCUMENT_MENU.ATTACHMENT_DESCRIPTION' | translate}} : </mat-card-content>
                </div>
                <div class="col-md-12 p-0 body-txt-left">
                  <mat-card-content class="p-0">{{attachment.category}}</mat-card-content>
                  <mat-card-content>{{attachment.description}}</mat-card-content>
                </div>
              </div> -->
            </mat-card>
        </div>
  </div>
</div>
