import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { QuickCreateAccount } from 'src/app/shared/models/quick-create.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { QuickCreateService } from 'src/app/shared/services/quick-create.service';

@Component({
  selector: 'app-quick-create-account-modal',
  templateUrl: './quick-create-account-modal.component.html',
  styleUrls: ['./quick-create-account-modal.component.scss']
})
export class QuickCreateAccountModalComponent {

  @ViewChild('template', { static: true }) template: any;
  accountCreateSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  accountForm: FormGroup;
  isLoading: boolean = false;
  quickCreateAccountReq: QuickCreateAccount;
  userInformationList: LookUpElement[] = [];
  userInformationSearch: string = '';

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
    private helpersService: HelpersService,
    private quickCreateSharedService: QuickCreateSharedService,
    private lookupsService: LookupsService,
    private quickCreateService: QuickCreateService
  ){
    this.accountCreateSubject = quickCreateSharedService
    .getSubjectQuickCreateAccountOpenModal()
    .pipe(takeUntil(this.destroy))
    .subscribe((sortByOpen: any) => {
      if(sortByOpen!= null && sortByOpen == 'Open') {
        this.showModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.quickCreateSharedService.setSubjectQuickCreateAccountOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createAccountForm();
    this.getUserInformationList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      // class: 'modal-dialog-centered modal-lg',
      class: 'modal-dialog-right modal-lg'
    });
  }

  createAccountForm() {
    this.accountForm = this.fb.group({
      AccountOwner: [''],
      AccountName: [''],
      Phone: ['', [Validators.pattern('[- +()0-9]{10,12}')]],
      Website: ['']
    });
  }

  cancelForm(){
    this.modalRef.hide();
    this.accountForm.reset();
  }

  createAccount(formData: any){
    this.setReqBody(formData);
    this.saveAccountInDB();
  }

  setReqBody(formData: any) {
    this.quickCreateAccountReq = {
      accountOwnerId: formData.AccountOwner,
      accountName: formData.AccountName,
      phone: formData.Phone ?? '',
      website: formData.Website ?? ''
    }
  }

  saveAccountInDB() {
    this.isLoading = true;
    this.quickCreateService
    .quickCreateAccount(this.quickCreateAccountReq)
    .subscribe((res: any) => {
      if(res) {
        this.cancelForm();
        this.helpersService.showCreateSuccessToast();
        this.quickCreateSharedService.setSubjectQuickCreateAccountOpenModal(null);
        this.quickCreateSharedService.setSubjectChooseAccountListTableChanged(true);
        this.isLoading = false;
      }
    }, () => {
      this.isLoading = false;
    });
  }

  getUserInformationList() {
    this.lookupsService.getUserInformationLookUp().pipe(takeUntil(this.destroy))
    .subscribe((userInformation: LookUpElement[]) => {
      this.userInformationList = userInformation;
    });
  }

}
