import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from '../models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { DealEdit } from 'src/app/home/deals/deal-edit/models/deal-edit.model';
import {
  NotAssignedDealsPaginated,
  DealsByReferenceIdPaginated,
} from '../models/deal-assignment.model';

@Injectable({
  providedIn: 'root',
})
export class DealAssignmentService {
  private apiDealUrl = 'api/Deal';

  constructor(public baseDataService: BaseDataService) {}

  getAllDealsByReferenceId(
    referenceId: string,
    module: string,
    filter: BaseFilterVm
  ): Observable<DealsByReferenceIdPaginated> {
    return this.baseDataService.makePostCall(
      `${this.apiDealUrl}/getAllDealsByReferenceId?referenceId=${referenceId}&module=${module}`,
      filter
    );
  }

  getAllNotAssignedDeals(
    module: string,
    filter: BaseFilterVm
  ): Observable<NotAssignedDealsPaginated> {
    return this.baseDataService.makePostCall(
      `${this.apiDealUrl}/getAllNotAssignedDeals?module=${module}`,
      filter
    );
  }

  assignMultipleDeals(
    referenceId: string,
    module: string,
    dealIds: string[]
  ): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiDealUrl}/assignMultipleDeals?referenceId=${referenceId}&module=${module}`,
      dealIds
    );
  }

  deleteDeal(dealId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiDealUrl}/${dealId}`);
  }

  unAssignDeal(dealId: string, module: string): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiDealUrl}/unAssignDeal?dealId=${dealId}&module=${module}`,
      ''
    );
  }

  getDealById(id: string): Observable<DealEdit> {
    return this.baseDataService.makeGetCall(`${this.apiDealUrl}/${id}`);
  }

  editDeal(deal: DealEdit): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiDealUrl}`, deal);
  }

  isDealEmailExists(id: string, email: string): Observable<any> {
    var params = id ? 'id=' + id + '&email=' + email : 'email=' + email;
    return this.baseDataService.makeGetCall(
      `${this.apiDealUrl}/isDealEmailExists?${params}`
    );
  }
}
