<div class="row mt-3">
  <mat-card class="custom-card">
    <mat-card-content>
      <div class="row">
        <div class="col-md-6 d-flex align-items-center">
          <h6>
            <b>{{ 'MAIN_MENU.DEALS' | translate }}</b>
          </h6>
        </div>
        <div class="col-md-6 d-flex flex-row-reverse align-items-center">
          <button
            mat-flat-button
            class="flat-info-btn btn-text-size"
            color="primary"
            [matMenuTriggerFor]="menu"
            *ngIf="isAssignVisible"
          >
            <mat-icon>expand_more</mat-icon>
            {{ 'DEALS_MENU.ASSIGN_DEALS' | translate }}
          </button>
          <button
            mat-flat-button
            class="flat-info-btn btn-text-size"
            color="primary"
            *ngIf="!isAssignVisible"
            (click)="addNewDeal()"
          >
            {{ 'COMMON.ASSIGN_NEW_DEAL' | translate }}
          </button>
          <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
            <button
              class="menu-custom-item"
              mat-menu-item
              (click)="addNewDeal()"
            >
              <span class="menu-custom-span">
                {{ 'COMMON.ASSIGN_NEW_DEAL' | translate }}
              </span>
            </button>
            <button
              class="menu-custom-item"
              mat-menu-item
              (click)="addExistingDeal()"
            >
              <span class="menu-custom-span">
                {{ 'COMMON.ASSIGN_EXISTING_DEALS' | translate }}
              </span>
            </button>
          </mat-menu>
          &nbsp;&nbsp;&nbsp;
          <div [formGroup]="searchForm">
            <mat-form-field
              class="example-full-width form-input-field form-input-field-readonly"
            >
              <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="Search"
                (input)="onSearchChange($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <table mat-table [dataSource]="dataSource" class="fleet-table">
            <ng-container matColumnDef="DealName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'DEALS_MENU.DEAL_NAME' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dealName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Amount">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'DEALS_MENU.AMOUNT' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.amount }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Stage">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'DEALS_MENU.STAGE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stage }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Probability">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                {{ 'DEALS_MENU.PROBABILITY' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.probability }}
              </td>
            </ng-container>
            <ng-container matColumnDef="ClosingDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'DEALS_MENU.CLOSING_DATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  this.helpersService
                    .getDateFromDb(element.closingDate)
                    ?.format('DD/MM/YYYY')
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Type">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                {{ 'DEALS_MENU.TYPE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <span
                  class="badge bg-purple badge-full-width"
                  *ngIf="element?.type === 'New Business'"
                  >{{ element.type }}</span
                >
                <span
                  class="badge bg-cyan badge-full-width"
                  *ngIf="element?.type === 'Existing Business'"
                  >{{ element.type }}</span
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="ApprovalState">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                {{ 'DEALS_MENU.APPROVAL_STATUS' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <span
                  class="badge bg-status-draft badge-full-width"
                  *ngIf="element?.approvalStatus === approvalStatus.DRAFT"
                  >{{ element.approvalStatus }}</span
                >
                <span
                  class="badge bg-green badge-full-width"
                  *ngIf="element?.approvalStatus === approvalStatus.APPROVED"
                  >{{ element.approvalStatus }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <a class="col-grey" (click)="openEdit(element.id)"
                  ><span class="material-icons-outlined">edit</span></a
                >
                <a class="col-red" (click)="deleteOrUnassignDeal(element.id)"
                  ><span class="material-icons-outlined">delete</span></a
                >
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div></div>

          <mat-paginator
            class="customPaginator"
            [pageSizeOptions]="[5, 10]"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [length]="totalItems"
            showFirstLastButtons
            (page)="onPageChange($event)"
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<app-quick-create-deal-modal
  [referenceId]="referenceId"
  [module]="module"
></app-quick-create-deal-modal>
<app-assign-existing-deals
  [referenceId]="referenceId"
  [module]="module"
></app-assign-existing-deals>
<app-edit-deal-assignment></app-edit-deal-assignment>
