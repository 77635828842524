<ng-template #template>
  <h5 class="modal-title modal-container quick-create-modal-rtl">
    {{ 'COMMON.QUICK_CREATE_CAMPAIGN' | translate }}
  </h5>
  <div class="container modal-container">
    <form [formGroup]="campaignForm">
      <div class="row quick-create-modal-rtl">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CAMPAIGN_MENU.TYPE' | translate }}</mat-label>
            <mat-select formControlName="CampaignTypeId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="campaignTypeSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let campaignType of campaignTypeList
                    | filter : campaignTypeSearch
                "
                [value]="campaignType.id"
                >{{ campaignType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>
              {{ 'CAMPAIGN_MENU.CAMPAIGN_NAME' | translate }}</mat-label
            >
            <input
              type="text"
              matInput
              formControlName="CampaignName"
              required
              pattern="\S(.*\S)?"
            />
            <mat-error
              *ngIf="campaignForm?.get('CampaignName').hasError('pattern')"
            >
              {{
                'COMMON.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate
              }}</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CAMPAIGN_MENU.STATUS' | translate }}</mat-label>
            <mat-select formControlName="CampaignStatusId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="campaignStatusSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let campaignStatus of campaignStatusList
                    | filter : campaignStatusSearch
                "
                [value]="campaignStatus.id"
                >{{ campaignStatus.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CAMPAIGN_MENU.START_DATE' | translate }}</mat-label>
            <input
              formControlName="StartDate"
              matInput
              [matDatepicker]="picker1"
              readonly
              required
              [min]="getCurrentDate()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CAMPAIGN_MENU.END_DATE' | translate }}</mat-label>
            <input
              formControlName="EndDate"
              matInput
              [matDatepicker]="picker2"
              readonly
              required
              [min]="campaignForm.get('StartDate')?.value"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>
              {{ 'CAMPAIGN_MENU.EXPECTED_REVENUE' | translate }}</mat-label
            >
            <input
              type="number"
              matInput
              formControlName="ExpectedRevenue"
              [min]="0"
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  &nbsp;&nbsp;&nbsp;
  <div class="row quick-create-modal-rtl ms-5">
    <div class="col-md-12 d-flex flex-row-reverse">
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn"
        *ngIf="isLoading"
      >
        <mat-spinner diameter="20"></mat-spinner></button
      >&nbsp;&nbsp;&nbsp;
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        [disabled]="!campaignForm.valid"
        (click)="createCampaign(campaignForm.value)"
        *ngIf="!isLoading"
      >
        {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon></button
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn btn-text-size"
        (click)="cancelForm()"
      >
        {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
