<ng-template #template>
  <h5 class="modal-title pull-left">
    <b>{{ 'CALLS_MENU.CANCEL_CALL' | translate }}</b>
  </h5>
  <div class="modal-body">
    <form [formGroup]="cancelCallForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field
            class="example-full-width form-input-field mat-form-field-readonly"
          >
            <mat-label>{{callDetails?.callToTypeName}}</mat-label>
            <input type="text" matInput formControlName="CallTo" readonly />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.NOTES' | translate }}</mat-label>
            <input type="text" matInput formControlName="Note" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12 mt-4 d-flex align text-align-right flex-row-reverse"
        >
          <div class="example-button-row">
            <button
              mat-stroked-button
              color="primary"
              class="btn-border outline-info-btn btn-text-size"
              (click)="cancelForm()"
            >
              {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
            </button>
            <button
              mat-flat-button
              class="flat-info-btn"
              color="primary"
              *ngIf="isLoading"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </button>
            <button
              mat-flat-button
              color="primary"
              class="flat-info-btn btn-text-size"
              (click)="cancelCall(cancelCallForm.value)"
              [disabled]="!cancelCallForm.valid"
              *ngIf="!isLoading"
            >
              {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
