import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FilterComponent } from './filter/filter.component';
import { SharedModule } from '../shared.module';
import { FilterSortingComponent } from './filter-modals/filter-sorting/filter-sorting.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { FilterColumnComponent } from './filter-modals/filter-column/filter-column.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterGroupComponent } from './filter-modals/filter-group/filter-group.component';
import { LoaderComponent } from './loader/loader.component';
import { Loader } from 'angular-feather/icons';
import { SidemenuModalComponent } from './sidemenu-modal/sidemenu-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CommonDocumentUploadComponent } from './common-document-upload/common-document-upload.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';

import { AttachmentListViewComponent } from './common-document-upload/components/attachment-list-view/attachment-list-view.component';
import { AttachmentGridViewComponent } from './common-document-upload/components/attachment-grid-view/attachment-grid-view.component';
import { FilterParametersComponent } from './filter-modals/filter-parameters/filter-parameters.component';
import { QuickCreateLeadModalComponent } from './quick-create-modals/quick-create-lead-modal/quick-create-lead-modal.component';
import { QuickCreateCampaignModalComponent } from './quick-create-modals/quick-create-campaign-modal/quick-create-campaign-modal.component';
import { QuickCreateDealModalComponent } from './quick-create-modals/quick-create-deal-modal/quick-create-deal-modal.component';
import { QuickCreateContactModalComponent } from './quick-create-modals/quick-create-contact-modal/quick-create-contact-modal.component';
import { QuickCreateAccountModalComponent } from './quick-create-modals/quick-create-account-modal/quick-create-account-modal.component';
import { AccountListModalComponent } from './common-choose-list-modals/account-list-modal/account-list-modal.component';
import { LeadListModalComponent } from './common-choose-list-modals/lead-list-modal/lead-list-modal.component';
import { CampaignListModalComponent } from './common-choose-list-modals/campaign-list-modal/campaign-list-modal.component';
import { ContactListModalComponent } from './common-choose-list-modals/contact-list-modal/contact-list-modal.component';
import { DealListModalComponent } from './common-choose-list-modals/deal-list-modal/deal-list-modal.component';
import { QuickAddCampaignModalComponent } from './quick-add-modals/quick-add-campaign-modal/quick-add-campaign-modal.component';
import { NotificationSettingModalComponent } from './notification-setting-modal/notification-setting-modal.component';
import { MeetingParticipantsComponent } from './meeting-participants/meeting-participants.component';
import { CommonCallModalComponent } from './common-call-modal/common-call-modal.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CallResheduleModalComponent } from './call-modals/call-reshedule-modal/call-reshedule-modal.component';
import { CallCancelModalComponent } from './call-modals/call-cancel-modal/call-cancel-modal.component';
import { CommonRepeatModalComponent } from './common-repeat-modal/common-repeat-modal.component';
import { CommonTimelineComponent } from './common-timeline/common-timeline.component';
import { DealAssignmentListComponent } from './deal-assignment/deal-assignment-list/deal-assignment-list.component';
import { AssignExistingDealsComponent } from './deal-assignment/assign-existing-deals/assign-existing-deals.component';
import { EditDealAssignmentComponent } from './deal-assignment/edit-deal-assignment/edit-deal-assignment.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CommonKanbanViewComponent } from './common-kanban-view/common-kanban-view.component';
import { DynamicFieldFormComponent } from './dynamic-field/dynamic-field-form/dynamic-field-form.component';
import { DynamicFieldViewComponent } from './dynamic-field/dynamic-field-view/dynamic-field-view.component';
import { CustomFieldFormComponent } from './custom-field/custom-field-form/custom-field-form.component';
import { CustomFieldViewComponent } from './custom-field/custom-field-view/custom-field-view.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DataNotFoundComponent } from './data-not-found/data-not-found.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    BreadcrumbComponent,
    FilterComponent,
    FilterSortingComponent,
    FilterColumnComponent,
    FilterGroupComponent,
    LoaderComponent,
    SidemenuModalComponent,
    CommonDocumentUploadComponent,
    AttachmentListViewComponent,
    AttachmentGridViewComponent,
    FilterParametersComponent,
    QuickCreateLeadModalComponent,
    QuickCreateCampaignModalComponent,
    QuickCreateDealModalComponent,
    QuickCreateContactModalComponent,
    QuickCreateAccountModalComponent,
    AccountListModalComponent,
    LeadListModalComponent,
    CampaignListModalComponent,
    ContactListModalComponent,
    DealListModalComponent,
    QuickAddCampaignModalComponent,
    NotificationSettingModalComponent,
    MeetingParticipantsComponent,
    CommonCallModalComponent,
    CallResheduleModalComponent,
    CallCancelModalComponent,
    CommonRepeatModalComponent,
    CommonTimelineComponent,
    DealAssignmentListComponent,
    AssignExistingDealsComponent,
    EditDealAssignmentComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    CommonKanbanViewComponent,
    DynamicFieldFormComponent,
    DynamicFieldViewComponent,
    CustomFieldFormComponent,
    CustomFieldViewComponent,
    TruncatePipe,
    DataNotFoundComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    TranslateModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    Ng2SearchPipeModule,
    NgxMatSelectSearchModule,
    DragDropModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    NgxMatTimepickerModule,
    MatSlideToggleModule,
    MatCardModule,
  ],
  exports: [
    FileUploadComponent,
    BreadcrumbComponent,
    FilterComponent,
    LoaderComponent,
    SidemenuModalComponent,
    CommonDocumentUploadComponent,
    QuickCreateLeadModalComponent,
    QuickCreateCampaignModalComponent,
    QuickCreateDealModalComponent,
    QuickCreateContactModalComponent,
    QuickCreateAccountModalComponent,
    AccountListModalComponent,
    LeadListModalComponent,
    CampaignListModalComponent,
    ContactListModalComponent,
    DealListModalComponent,
    QuickAddCampaignModalComponent,
    MeetingParticipantsComponent,
    CommonCallModalComponent,
    CallResheduleModalComponent,
    CallCancelModalComponent,
    CommonRepeatModalComponent,
    CommonTimelineComponent,
    DealAssignmentListComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    CommonKanbanViewComponent,
    DynamicFieldFormComponent,
    DynamicFieldViewComponent,
    CustomFieldFormComponent,
    CustomFieldViewComponent,
    TruncatePipe,
    DataNotFoundComponent
  ],
})
export class ComponentsModule {}
