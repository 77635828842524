import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QuickCreateContact } from 'src/app/shared/models/quick-create.model';
import { QuickCreateService } from 'src/app/shared/services/quick-create.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';

@Component({
  selector: 'app-quick-create-contact-modal',
  templateUrl: './quick-create-contact-modal.component.html',
  styleUrls: ['./quick-create-contact-modal.component.scss'],
})
export class QuickCreateContactModalComponent {
  userSearch: string;
  modalRef: BsModalRef;
  @Input() module: null;
  accountSearch: string;
  contactForm: FormGroup;
  @Input() accountId: null;
  isLoading: boolean = false;
  userList: LookUpElement[] = [];
  accountList: LookUpElement[] = [];
  contactCreateSubject: Subscription;
  quickCreateContactReq: QuickCreateContact;
  @ViewChild('template', { static: true }) template: any;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private helpersService: HelpersService,
    private lookupsService: LookupsService,
    private translateService: TranslateService,
    private quickCreateService: QuickCreateService,
    private quickCreateSharedService: QuickCreateSharedService
  ) {
    this.contactCreateSubject = quickCreateSharedService
      .getSubjectQuickCreateContactOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.quickCreateSharedService.setSubjectQuickCreateContactOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createContactForm();
    this.getLookupList();
    this.getUsersList();
  }

  getLookupList() {
    this.getAccountList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      // class: 'modal-dialog-centered modal-lg',
      class: 'modal-dialog-right modal-lg',
    });
    if (this.accountId && this.module == 'ACCOUNT') {
      this.contactForm?.get('AccountId')?.setValue(this.accountId);
      this.contactForm?.get('AccountId')?.disable();
    }
  }

  createContactForm() {
    this.contactForm = this.fb.group({
      FirstName: [''],
      LastName: [''],
      AccountId: [
        this.accountId && this.module == 'ACCOUNT' ? this.accountId : null,
      ],
      Email: [
        '',
        [
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      Phone: ['', [Validators.pattern('[- +()0-9]{10,12}')]],
      ContactOwner: [null],
    });
    if (this.accountId && this.module == 'ACCOUNT') {
      this.contactForm?.get('AccountId')?.disable();
    }
  }

  getAccountList() {
    this.lookupsService
      .getAccountLookup()
      .subscribe((accountList: LookUpElement[]) => {
        this.accountList = accountList;
      });
  }

  cancelForm() {
    this.modalRef.hide();
    this.contactForm.reset();
  }

  createContact(formData: any) {
    this.setReqBody(formData);
    this.saveContactInDB();
  }

  setReqBody(formData: any) {
    this.quickCreateContactReq = {
      firstName: formData.FirstName,
      lastName: formData.LastName ?? '',
      accountId:
        this.accountId && this.module == 'ACCOUNT'
          ? this.accountId
          : formData.AccountId,
      email: formData.Email,
      phone: formData.Phone ?? '',
      contactOwnerId: formData.ContactOwner,
    };
  }

  saveContactInDB() {
    this.isLoading = true;
    this.quickCreateService
      .quickCreateContact(this.quickCreateContactReq)
      .subscribe(
        (res: any) => {
          if (res) {
            this.cancelForm();
            this.helpersService.showCreateSuccessToast();
            this.quickCreateSharedService.setSubjectQuickCreateContactOpenModal(
              null
            );
            this.quickCreateSharedService.setSubjectChooseContactListTableChanged(
              true
            );
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  getUsersList() {
    this.lookupsService
      .getUserInformationLookUp()
      .pipe(takeUntil(this.destroy))
      .subscribe((userList: LookUpElement[]) => {
        this.userList = userList;
      });
  }
}
