import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteInfo, RouteInfoSide } from '../sidebar.metadata';
@Injectable({
  providedIn: 'root'
})
export class SidebarSharedService {
  private subjectSubmenuModal = new BehaviorSubject(null);
  private subjectSubmenuModalOpen = new BehaviorSubject(null);
  constructor() { }
  setsubjectSideMenuData(rounteInfo: RouteInfoSide) {
    this.subjectSubmenuModal.next(rounteInfo);
  }

  getsubjectSideMenuData(): Observable<RouteInfoSide> {
    return this.subjectSubmenuModal.asObservable();
  }
  setsubjectSideMenuOpen(open: string) {
    this.subjectSubmenuModalOpen.next(open);
  }

  getsubjectSideMenuOpen(): Observable<string > {
    return this.subjectSubmenuModalOpen.asObservable();
  }
}
