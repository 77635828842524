<ng-template #template>
  <div class="row block-bg">
    <div class="col-md-4 d-flex align-items-center">
      <mat-icon class="mb-2" (click)="backClick()">arrow_back</mat-icon>
      &nbsp;&nbsp;
      <h5>
        <b *ngIf="!isEdit && !isSchedule">
          {{ 'LEADS_MENU.LOG_A_CALL' | translate }}
        </b>
        <b *ngIf="!isEdit && isSchedule">
          {{ 'CALLS_MENU.SCHEDULE_A_CALL' | translate }}
        </b>
        <b *ngIf="isEdit">
          {{ 'CALLS_MENU.EDIT_CALL' | translate }}
        </b>
      </h5>
    </div>
    <div class="col-md-8 d-flex flex-row-reverse align-items-center">
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        *ngIf="!isLoading"
        (click)="createOrEditCall(callForm.value, approvalStatus.APPROVED)"
        [disabled]="!callForm.valid"
      >
        {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon>
      </button>
      <button
        mat-flat-button
        class="flat-info-btn"
        color="primary"
        *ngIf="isLoading"
      >
        <mat-spinner diameter="20"></mat-spinner>
      </button>
      &nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn"
        *ngIf="!isLoading"
        (click)="createOrEditCall(callForm.value, approvalStatus.DRAFT)"
        [disabled]="!callForm.valid"
      >
        {{ 'COMMON.SAVE_AS_DRAFT' | translate }}
        <mat-icon>save</mat-icon>
      </button>
      &nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn"
        (click)="cancelForm()"
      >
        {{ 'COMMON.CANCEL' | translate }}
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
  <div class="modal-body p-2 mt-3">
    <form [formGroup]="callForm">
      <div class="row">
        <div class="col-md-12 d-flex align-items-center">
          <h6>
            <b>{{ 'LEADS_MENU.CALL_INFO' | translate }}</b>
          </h6>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field
            class="example-full-width form-input-field"
            [ngClass]="isSchedule || isEdit ? 'mat-form-field-readonly' : ''"
          >
            <mat-label>{{ 'LEADS_MENU.CALL_TYPE' | translate }}</mat-label>
            <mat-select
              formControlName="CallTypeId"
              (selectionChange)="hancleSelectCallType($event)"
              required
            >
              <mat-option
                class="form-select-option"
                *ngFor="let callType of callTypeList"
                [value]="callType.id"
                [disabled]="isSchedule || isEdit"
                >{{ callType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="selectedCallType == callTypes.OUTBOUND">
          <mat-form-field
            class="example-full-width form-input-field"
            [ngClass]="isEdit || isSchedule ? 'mat-form-field-readonly' : ''"
          >
            <mat-label>{{
              'LEADS_MENU.OUTGOING_CALL_STATUS' | translate
            }}</mat-label>
            <mat-select formControlName="OutgoingCallStatusId">
              <mat-option
                class="form-select-option"
                *ngFor="let callStatus of callStatusList"
                [value]="callStatus.id"
                [disabled]="isEdit || isSchedule"
                >{{ callStatus.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <mat-form-field
            class="example-full-width form-input-field"
            [ngClass]="isEdit ? 'mat-form-field-readonly' : ''"
          >
            <mat-label>{{ 'LEADS_MENU.START_DATE' | translate }}</mat-label>
            <input
              formControlName="ToDate"
              matInput
              [matDatepicker]="picker1"
              readonly
              required
              [max]="!isEdit && !isSchedule ? getCurrentDate() : null"
              [min]="!isEdit && isSchedule ? getCurrentDate() : null"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
              [disabled]="isEdit"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1 [disable]="isEdit"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field
            class="example-full-width form-input-field"
            [ngClass]="isEdit ? 'mat-form-field-readonly' : ''"
          >
            <mat-label>{{ 'LEADS_MENU.TIME' | translate }}</mat-label>
            <input
              matInput
              formControlName="ToTime"
              readonly
              [ngxMatTimepicker]="pickerA"
              required
              [disableClick]="isEdit"
              [max]="
                helpersService.getDateFromDateTime(getCurrentDate()) ==
                  helpersService.getDateFromDateTime(
                    callForm.get('ToDate').value
                  ) &&
                !isEdit &&
                !isSchedule
                  ? helpersService.getTimeFromDateTime(getCurrentDate())
                  : null
              "
              [min]="
                helpersService.getDateFromDateTime(getCurrentDate()) ==
                  helpersService.getDateFromDateTime(
                    callForm.get('ToDate').value
                  ) &&
                !isEdit &&
                isSchedule
                  ? helpersService.getTimeFromDateTime(getCurrentDate())
                  : null
              "
            />
            <mat-icon matSuffix (click)="pickerA.open()" *ngIf="!isEdit">
              watch_later
            </mat-icon>
            <ngx-mat-timepicker #pickerA></ngx-mat-timepicker>
          </mat-form-field>
        </div>
        <div
          class="col-md-6"
          *ngIf="!isSchedule"
          [ngClass]="selectedCallType == callTypes.MISSED ? 'd-none' : ''"
        >
          <mat-form-field
            class="example-full-width form-input-field"
            [ngClass]="isEdit ? 'mat-form-field-readonly' : ''"
          >
            <mat-label
              >{{ 'CALLS_MENU.CALL_DURATION' | translate }} ({{
                'CALLS_MENU.MINUTES' | translate
              }})</mat-label
            >
            <input
              type="number"
              formControlName="Minutes"
              [readonly]="isEdit"
              [required]="!isCallMissed"
              min="0"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="isSchedule">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CALLS_MENU.CALL_OWNER' | translate }}</mat-label>
            <mat-select formControlName="CallOwnerId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="userSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                  ></ngx-mat-select-search
                >
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let user of usersList | filter : userSearch"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.SUBJECT' | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Subject"
              maxlength="40"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="isSchedule">
          <div class="row">
            <div class="col-md-6 mt-2">
              <mat-label class="text-active">{{
                'LEADS_MENU.REMINDER' | translate
              }}</mat-label>
            </div>
            <div class="col-md-6 mt-2">
              <mat-slide-toggle formControlName="IsReminder">
              </mat-slide-toggle>
            </div>
            <div
              [ngClass]="
                callForm.get('IsReminder').value ? 'row mt-4' : 'd-none'
              "
            >
              <div class="col-md-4">
                <mat-form-field class="example-full-width form-input-field">
                  <mat-label>{{ 'MEETING_MENU.ON' | translate }}</mat-label>
                  <input
                    formControlName="ReminderOn"
                    matInput
                    [matDatepicker]="picker3"
                    [required]="callForm.get('IsReminder').value"
                    [max]="callForm.get('ToDate').value"
                    [min]="getCurrentDate()"
                    readonly
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                  <mat-error *ngIf="callForm.get('ReminderOn').hasError('max')">
                    Invalid reminder date
                  </mat-error>
                  <mat-error *ngIf="callForm.get('ReminderOn').hasError('min')">
                    Invalid reminder date
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="example-full-width form-input-field">
                  <mat-label>{{ 'MEETING_MENU.AT' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="ReminderAt"
                    readonly
                    [ngxMatTimepicker]="pickerC"
                    [required]="callForm.get('IsReminder').value"
                    [max]="
                      callForm.get('ToDate')?.value ==
                        callForm.get('ReminderOn')?.value &&
                      !isEdit &&
                      isSchedule
                        ? callForm.get('ToTime')?.value
                        : null
                    "
                  />
                  <mat-icon matSuffix (click)="pickerC.open()">
                    watch_later
                  </mat-icon>
                  <ngx-mat-timepicker #pickerC></ngx-mat-timepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="example-full-width form-input-field">
                  <mat-label>{{ 'MEETING_MENU.TYPE' | translate }}</mat-label>
                  <mat-select
                    formControlName="ReminderType"
                    [required]="callForm.get('IsReminder').value"
                  >
                    <mat-option
                      class="form-select-option"
                      *ngFor="let reminder of reminderTypeList"
                      [value]="reminder.id"
                      >{{ reminder.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isSchedule && isEdit">
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.DESCRIPTION' | translate }}</mat-label>
            <input type="text" matInput formControlName="Description" />
          </mat-form-field>
        </div>
      </div>

      <div class="mar-description-h">
        <h6>
          <b>
            {{ 'LEADS_MENU.PURPOSE_OUT_CALL' | translate }}
          </b>
        </h6>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.CALL_PURPOSE' | translate }}</mat-label>
            <mat-select formControlName="CallPurposeId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let callPurpose of callPurposeList"
                [value]="callPurpose.id"
                >{{ callPurpose.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.CALL_AGENDA' | translate }}</mat-label>
            <input type="text" matInput formControlName="CallAgenda" />
          </mat-form-field>
        </div>
      </div>

      <div class="mar-description-h" *ngIf="!isSchedule">
        <h6>
          <b>
            {{ 'LEADS_MENU.OUTCOME_OUT_CALL' | translate }}
          </b>
        </h6>
      </div>

      <div class="row" *ngIf="!isSchedule">
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.CALL_RESULT' | translate }}</mat-label>
            <mat-select formControlName="CallResultId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let callResult of callResultList"
                [value]="callResult.id"
                >{{ callResult.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.DESCRIPTION' | translate }}</mat-label>
            <input type="text" matInput formControlName="Description" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>
