import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData } from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnDestroy {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];
  @Input() filterForm: FormGroup;
  groupBySearch: string;

  constructor(
    private modalService: BsModalService,
    private filterSharedService: FilterSharedService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.filterDataSubject = filterSharedService
      .getsubjectFilterData()
      .pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;
          this.coloumnsData = filterData.ColumnsData.filter(
            (option) => option.isGroupbyCoulmn
          );
        }
      });
    this.filterDataSubject = filterSharedService
      .getsubjectgroupOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal();
        }
      });

    this.filterDataSubject = filterSharedService
      .getsubjectFilterReset()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {
          this.resetFilter(true);
        }
      });
  }
  ngOnInit() {
    this.initializeForm();
  }
  initializeForm() {
    //   this.sortForm = this.fb.group({

    //     search: ['', Validators.required],
    //     sortBy: ['', Validators.required],
    //     sortType: ['', Validators.required],

    // });
    // this.filterForm.addControl("search", new FormControl());
    this.filterForm?.addControl('groupBy', new FormControl());
  }
  private showModal() {
    // this.offerForm.get("operationalHours").setValue(this.fb.array([]));
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right ',
    });
  }

  searchChange(event: any) {
    const filterValue = event.target.value.toLowerCase();

    this.coloumnsData = this.filterData.ColumnsData.filter((option) =>
      // option.title.toLowerCase().includes(filterValue) && option.isGroupbyCoulmn
      this.translateService.instant(option.translate).toLowerCase().includes(filterValue) && option.isGroupbyCoulmn
    );
    // this.coloumnsData = this.filterData.ColumnsData.filter(
    //   (option) => option.isGroupbyCoulmn
    // );
  }

  resetFilter(isClearFromMainFilterMenu = false) {
    this.groupBySearch = '';
    this.filterForm?.patchValue({
      groupBy: null,
    });

    this.coloumnsData = this.filterData?.ColumnsData.filter(
      (option) => option.isGroupbyCoulmn
    );

    if(!isClearFromMainFilterMenu) {
      this.filterSharedService.setsubjectApplyFilter(true);
    }
  }

  applyFilter() {
    this.filterSharedService.setsubjectApplyFilter(true);
  }

  ngOnDestroy() {
    this.filterSharedService.setsubjectgroupOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }
}
