import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  ActivityTask,
  ContactView,
  OpenActivityTask,
  OpenedClosedActivitiesCount,
} from '../models/contact-view.model';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';

@Injectable({
  providedIn: 'root',
})
export class ContactViewService {
  private apiTask = 'api/Task';
  private apiContactUrl = 'api/Contact';

  constructor(private baseDataService: BaseDataService) {}

  getContactById(id: string): Observable<ContactView> {
    return this.baseDataService.makeGetCall(`${this.apiContactUrl}/${id}`);
  }

  getContactNumber(): Observable<any> {
    return this.baseDataService.makeGetCall(
      `${this.apiContactUrl}/getContactNumber`
    );
  }

  editContact(contactEdit: ContactView): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiContactUrl}`,
      contactEdit
    );
  }

  contactClone(id: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiContactUrl}/clone?contactId=${id}`
    );
  }

  deleteContact(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiContactUrl}/${id}`);
  }

  createTask(task: ActivityTask): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiTask}`, task);
  }
  getTaskList(filter: BaseFilterVm): Observable<OpenActivityTask[]> {
    return this.baseDataService.makePostCall(
      `${this.apiTask}/getAlltasks`,
      filter
    );
  }

  getTasksByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityTask[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getTasksByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  // Opened Closed Activities Count
  getOpenedClosedActivitiesByReferenceId(
    id: string,
    module: string
  ): Observable<OpenedClosedActivitiesCount> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getOpenedClosedActivitiesByReferenceId?referenceId=${id}&module=${module}`
    );
  }
}
