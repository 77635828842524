import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { ChangePassword } from '../models/common-data.model';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  private apiChangePasswordUrl = 'api/UserInformation/changeUserPasswordAsync';

  constructor(private baseDataService: BaseDataService) {}

  changePassword(changePasswordReq: ChangePassword): Observable<boolean> {
    return this.baseDataService.makePostCall(
      this.apiChangePasswordUrl,
      changePasswordReq
    );
  }}
