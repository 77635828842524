import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { CallLog, CampaignView, OpenActivityCall } from '../models/campaign-view.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignViewService {
  private apiCall = 'api/Call';
  private apiCampaignUrl = 'api/Campaign';

  constructor(private baseDataService: BaseDataService) {}

  getCampaignById(id: string): Observable<CampaignView> {
    return this.baseDataService.makeGetCall(`${this.apiCampaignUrl}/${id}`);
  }

  deleteCampaign(campaignId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiCampaignUrl}/${campaignId}`
    );
  }

  cloneCampaign(campaignId: any): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/clone?campaignId=${campaignId}`
    );
  }

  createCallLog(callLog: CallLog): Observable<CallLog> {
    return this.baseDataService.makePostCall(`${this.apiCall}`, callLog);
  }
  getAllCallLogs(filter: BaseFilterVm): Observable<CallLog[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCall}/getAllCalls`,
      filter
    );
  }
  getAllCallLogsByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityCall[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCall}/getCallsByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }
}
