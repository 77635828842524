import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { AuthService } from '../../core/service/auth.service';

import { Router } from '@angular/router';
import { RightSidebarService } from '../../core/service/rightsidebar.service';
import { Role } from '../../core/models/role';
import { LanguageService } from '../../core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from '../../shared/UnsubscribeOnDestroyAdapter';
import { DirectionService } from '../../core/service/direction.service';
import { User } from '../../core/models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Constants, CRM_ADMIN_ROLE, Toast } from '../../shared/data/constants';
import { SignalrService } from '../../core/service/signalr.service';
import {
  CompanyLookupModel,
  NotificationModel,
} from '../../shared/models/common-data.model';
import { NotificationDataService } from '../../core/service/notification-data.service';
import { NotificationList } from '../../core/models/notification.model';
import { HelpersService } from '../../shared/services/helpers.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CommonSharedService } from '../../shared/services/common-shared.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CompanyProfileService } from '../../home/administration/company-profile/services/company-profile.service';
import { CompanyProfile } from '../../home/administration/company-profile/models/company-profile.model';
import { ChangePasswordSharedService } from '../../shared/services/change-password-shared.service';
import { CommonService } from '../../shared/services/common.service';
import { ApprovalWorkflowListSharedService } from '../../home/approval-workflow-list/services/approval-workflow-list-shared.service';

const document: any = window.document;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  userImg: string;
  homePage: string;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  currentUser: User;
  notificationUnreadCount: number = 0;
  notificationForm: FormGroup;
  audioFile: any = null;

  isRtl = false;
  isNavbarCollapsed = false;
  isLoading: boolean = false;
  isShowUnread: boolean = true;
  isCompaniesLoading: boolean = false;

  currentCompany: CompanyLookupModel;

  notificationList: NotificationList[] = [];
  companyLookup: CompanyLookupModel[] = [];

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private directionService: DirectionService,
    private fb: FormBuilder,
    private signalrService: SignalrService,
    private notificationDataService: NotificationDataService,
    private helpersService: HelpersService,
    private translateService: TranslateService,
    private commonSharedService: CommonSharedService,
    private companyProfileService: CompanyProfileService,
    private changePasswordSharedService: ChangePasswordSharedService,
    private commonService: CommonService,
    private approvalWorkflowListSharedService: ApprovalWorkflowListSharedService
  ) {
    super();

    this.commonSharedService
      .getSubjectCompanyListChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.authService
            .fetchUserCompanies()
            .subscribe((resCompany: CompanyLookupModel[]) => {
              localStorage.setItem('userCompanies', JSON.stringify(resCompany));
              this.getUserCompanies();
            });
        }
      });
  }

  listLang = [
    { text: 'EN', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'AR', flag: 'assets/images/flags/oman.png', lang: 'ar' },
  ];
  notifications: any[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
  ];
  ngOnInit() {
    this.config = this.configService.configData;
    this.currentUser = this.authService.currentUserValue;
    this.userImg = this.authService.currentUserValue.img;

    this.createNotificationForm();
    this.getNotificationUnreadCount();
    this.getUserCompanies();

    this.langStoreValue = localStorage.getItem('lang') ?? 'en';
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'EN';
      }
    } else {
      this.flagvalue = val.map((element) => element.text);
    }
    this.isNavbarCollapsed = true;

    this.connectNotifications();

    this.checkIsDynamicEnable(this.currentCompany?.id);
  }

  connectNotifications() {
    this.signalrService.connect();

    this.signalrService
      .getMessageObservable()
      .subscribe((message: NotificationModel) => {
        if (
          message.companyProfileId ==
          this.authService?.getCurrentCompanyValue?.id
        ) {
          if (message.type === 'WORKFLOWUPDATED') {
            this.approvalWorkflowListSharedService.setSubjectPendingApprovalWorkflowTableChanged(
              true
            );
          }
        }

        if (message.userInformationId == this.authService.currentUserValue.id) {
          if (message.type === 'INAPP') {
            this.getNotificationUnreadCount();

            setTimeout(() => {
              // this.loadNotifications();
              this.initializeAudio();
              this.playAudio();
              Toast.fire({
                icon: 'info',
                title: message.title + '<br><hr>' + message.notification,
              });
            }, 2500);
          }

          if (message.type === 'USERLOGOUT') {
            Toast.fire({
              icon: 'info',
              title: message.notification,
            });

            setTimeout(() => {
              this.logout();
            }, 5000);
          }

          if (
            message.type === 'COMPANYDELETED' &&
            message.companyProfileId === this.currentCompany.id
          ) {
            Toast.fire({
              icon: 'info',
              title: message.notification,
            });

            setTimeout(() => {
              this.logout();
            }, 5000);
          }
        }
      });
  }

  createNotificationForm() {
    this.notificationForm = this.fb.group({
      isUnread: true,
    });
  }

  initializeAudio() {
    this.audioFile = new Audio();
    this.audioFile.src = '../../../../../assets/audio/notification.wav';
    // audio.muted = true;
    this.audioFile.load();
  }

  playAudio() {
    var promise = this.audioFile.play();
    if (promise !== undefined) {
      promise
        .then((_) => {
          // Autoplay started!
        })
        .catch((error) => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.

          this.playAudio();
        });
    }
  }

  handleOnClickNotification() {
    this.notificationForm.get('isUnread')?.setValue(true);
    this.isShowUnread = true;
    this.loadNotifications(this.isShowUnread);
  }

  loadNotifications(isUnreadOnly = false) {
    this.isLoading = true;
    this.notificationDataService
      .getAllInAppNotifications(isUnreadOnly)
      .subscribe(
        (res) => {
          this.notificationList = res;
          this.isLoading = false;
        },
        (error) => {}
      );
  }

  getNotificationUnreadCount() {
    this.isLoading = true;
    this.notificationDataService.getNotificationUnreadCount().subscribe(
      (res) => {
        this.notificationUnreadCount = res;
        this.isLoading = false;
      },
      (error) => {}
    );
  }

  setNotificationMarkAsRead(id: string) {
    this.isLoading = true;
    this.notificationDataService.markNotificationAsReadById(id).subscribe(
      (res) => {
        if (res) {
          this.loadNotifications();
          this.getNotificationUnreadCount();
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  markAllNotificationsAsRead() {
    this.isLoading = true;
    this.notificationDataService.markAllNotificationsAsRead().subscribe(
      (res) => {
        if (res) {
          this.isShowUnread = false;
          this.notificationForm.get('isUnread')?.setValue(false);
          this.loadNotifications();
          this.getNotificationUnreadCount();
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  showUnreadOrReadNotifications(event: any) {
    this.loadNotifications(event.checked);
    this.getNotificationUnreadCount();
    this.isShowUnread = event.checked;
  }

  getFromNow(dateInUtc: Date) {
    return this.helpersService.getDateFromDb(dateInUtc).fromNow(true);
  }

  onChangeCompany(company: CompanyLookupModel) {
    this.checkCompanyAvailability(company);
  }

  checkCompanyAvailability(company: CompanyLookupModel) {
    const isCRMAdmin = this.currentUser?.role?.includes(CRM_ADMIN_ROLE);

    this.companyProfileService
      .checkCompanyAvailability(company.id, isCRMAdmin)
      .subscribe(
        (res: boolean) => {
          if (res) {
            this.setCompany(company);
          } else {
            Toast.fire({
              icon: 'info',
              title: 'Company unavailable. Please contact the administrator!',
            });

            setTimeout(() => {
              this.logout();
            }, 5000);
          }
        },
        (err) => {}
      );
  }

  setCompany(company: CompanyLookupModel) {
    Swal.fire({
      title: this.translateService.instant(
        'COMMON.ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THE_COMPANY'
      ),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e0272a',
      cancelButtonColor: 'transparent',
      confirmButtonText: this.translateService.instant('COMMON.CONFIRM'),
      cancelButtonText: this.translateService.instant('COMMON.CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentCompany = company;
        this.authService.setCurrentCompany(company);
        this.changeCompanyRoles();
      }
    });
  }

  changeCompanyRoles() {
    var user = this.authService.currentUserValue;

    this.router.navigate(['/dashboard']);
    if (user.role.includes(CRM_ADMIN_ROLE)) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      this.authService
        .getPolicyRoles(user.id, this.currentCompany?.id ?? null)
        .subscribe((rolesRes) => {
          user.role = rolesRes;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.authService.currentUserSubject.next(user);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
    }
  }

  getUserCompanies() {
    this.isCompaniesLoading = true;
    this.companyLookup = this.authService.getUserCompanies();
    this.authService.setCurrentCompany(
      JSON.parse(localStorage.getItem('currentCompany'))
    );
    this.currentCompany = this.authService.currentCompany;
    if (!this.authService.currentUserValue.role.includes(CRM_ADMIN_ROLE)) {
      if (this.companyLookup.length == 0) {
        Toast.fire({
          icon: 'error',
          title:
            'You are not assigned to a company. Please contact an administrator.',
        });

        setTimeout(() => {
          this.logout();
        }, 3000);
        // this.router.navigate(['/authentication/signin']);
      }
    }
    this.isCompaniesLoading = false;
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    // this.flagvalue = flag;
    this.flagvalue = text;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);

    this.switchDirection(lang == 'ar');
    if (this.currentCompany) {
      this.getCompanyProfiletById();
    }
    this.fetchUserCompanies();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  fetchUserCompanies() {
    this.authService
      .fetchUserCompanies()
      .subscribe((resCompany: CompanyLookupModel[]) => {
        this.companyLookup = resCompany;
        localStorage.setItem('userCompanies', JSON.stringify(resCompany));
      });
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  switchDirection(isRtl: boolean) {
    var isrtl: string = String(isRtl);
    if (
      isrtl === 'false' &&
      document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
      this.renderer.removeClass(this.document.body, 'rtl');
      this.directionService.updateDirection('ltr');
    } else if (
      isrtl === 'true' &&
      !document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      this.renderer.addClass(this.document.body, 'rtl');
      this.directionService.updateDirection('rtl');
    }
    localStorage.setItem('isRtl', isrtl);
    this.isRtl = isRtl;
  }

  setRTLSettings() {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    this.renderer.addClass(this.document.body, 'rtl');
    this.isRtl = true;
    localStorage.setItem('isRtl', 'true');
  }

  setLTRSettings() {
    document.getElementsByTagName('html')[0].removeAttribute('dir');
    this.renderer.removeClass(this.document.body, 'rtl');
    this.isRtl = false;
    localStorage.setItem('isRtl', 'false');
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.isNavbarCollapsed = false;
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.isNavbarCollapsed = true;
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
        window.location.reload();
      }
    });
  }

  getCompanyProfiletById() {
    this.companyProfileService
      .getCompanyProfiletById(this.currentCompany.id)
      .subscribe(
        (res: CompanyProfile) => {
          if (this.langStoreValue == 'en') {
            this.currentCompany.name = res.profileName;
          } else {
            this.currentCompany.name = res.profileSecondaryName;
          }

          this.authService.setCurrentCompany(this.currentCompany);
        },
        (err) => {}
      );
  }

  onClickChangePassword() {
    this.changePasswordSharedService.setSubjectOpenChangePasswordModal('Open');
  }

  checkIsDynamicEnable(id: string) {
    this.commonService.checkIsDynamicEnable(id).subscribe(
      (res) => {
        localStorage.setItem('dynamicFieldConfigurationStatus', res);
      },
      (error) => {}
    );
  }
}
