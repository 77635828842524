import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { QuickCreateLead, QuickCampaign, QucikCreateDeal, QuickCreateContact, QuickCreateAccount } from '../models/quick-create.model';

@Injectable({
  providedIn: 'root'
})
export class QuickCreateService {

  private apiLeadsUrl='api/Leads/quickCreateLead';
  private apiCampaignUrl = 'api/Campaign/quickCreateCampaign';
  private apiQuickCrerateDealUrl = 'api/Deal/quickCreateDeal';
  private apiQuickCrerateContactUrl = 'api/Contact/quickCreateContact';
  private apiQuickCrerateAccountUrl = 'api/Account/quickCreateAccount';

  constructor( public baseDataService: BaseDataService) { }

  quickCreateLead(quickCreateLead : QuickCreateLead): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiLeadsUrl}`, quickCreateLead)
  }

  quickCreateCampaign(quickCampign: QuickCampaign): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiCampaignUrl}`, quickCampign)
  }

  quickCreateDeal(qucikCreateDeal: QucikCreateDeal): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiQuickCrerateDealUrl}`, qucikCreateDeal)
  }

  quickCreateContact(quickCreateContact: QuickCreateContact): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiQuickCrerateContactUrl}`, quickCreateContact)
  }

  quickCreateAccount(quickCreateAccount: QuickCreateAccount): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiQuickCrerateAccountUrl}`, quickCreateAccount)
  }
}
