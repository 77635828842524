// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://crmapi.codezync.net/',
  authUrl: 'https://keycloak-dev.gearsivms.com',
  clientDBId: 'a45668a5-c519-48df-8b26-36734cdc0024',
  clientId: 'crm-qa-client',
  clientSecret: 'FlQuWYjdXt51Aj4ED0ran0cAHfwbrBoZ',
  realm: 'crm-qa-realm',
  recaptcha: {
    siteKey: '6LeWSE0pAAAAAEX-YxKyQL98QlcNJceMEX3oMD1o',
  },
  signalRUrl: 'https://crmapi.codezync.net/notificationHub',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
