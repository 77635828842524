<div [formGroup]="parentForm">
  <div formArrayName="DynamicFields">
    <mat-card class="custom-card mt-3">
      <div class="row mt-2">
        <div class="col-md-8 dynamic-field-container">
          <mat-card-content>
            <div class="row">
              <div class="col-md-10">
                <p class="formSubTitle mt-2">
                  {{ 'COMMON.ADDITIONAL_INFORMATION' | translate }}
                </p>
              </div>
              <div class="col-md-2">
                <button
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a delete icon"
                  (click)="onAddField()"
                  *ngIf="dynamicFields?.length != 0"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row mt-2" *ngIf="dynamicFields?.length != 0">
                <table>
                  <tr>
                    <div class="row">
                      <div class="col-md-5 d-flex justify-content-center">
                        <th>{{ 'COMMON.DYNAMIC_PROPERTY' | translate }}</th>
                      </div>
                      <div class="col-md-5 d-flex justify-content-center">
                        <th>
                          {{ 'COMMON.VALUE' | translate }}
                        </th>
                      </div>
                    </div>
                  </tr>

                  <tr
                    *ngFor="let field of formArray?.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="row mt-2">
                      <div class="col-md-5">
                        <mat-form-field
                          class="example-full-width form-input-field"
                        >
                          <mat-select
                            formControlName="LabelId"
                            (selectionChange)="onLabelIdChange(i)"
                          >
                            <mat-option
                              *ngFor="let dynamicField of dynamicFields"
                              [value]="dynamicField.id"
                              >{{ dynamicField.label }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-md-5">
                        <div *ngIf="field?.get('LabelId')?.value">
                          <ng-container
                            [ngSwitch]="
                              mapDataType(field?.get('LabelId')?.value)
                            "
                          >
                            <div *ngSwitchCase="dataType.TEXT">
                              <mat-form-field
                                class="example-full-width form-input-field"
                              >
                                <input
                                  type="text"
                                  matInput
                                  formControlName="Value"
                                  [required]="field?.get('LabelId')?.value"
                                  pattern="\S(.*\S)?"
                                />
                                <mat-error
                                  *ngIf="
                                    field?.get('Value').hasError('pattern')
                                  "
                                >
                                  {{
                                    'COMMON.WHITESPACE_CANT_CONTAIN_START_AND_END'
                                      | translate
                                  }}</mat-error
                                >
                              </mat-form-field>
                            </div>

                            <div *ngSwitchCase="dataType.URL">
                              <mat-form-field
                                class="example-full-width form-input-field"
                              >
                                <mat-label>{{
                                  field?.get('label')?.value
                                }}</mat-label>
                                <input
                                  type="text"
                                  matInput
                                  formControlName="Value"
                                  [required]="field?.get('LabelId')?.value"
                                  pattern="\S(.*\S)?"
                                />
                                <mat-error
                                  *ngIf="
                                    field?.get('Value').hasError('pattern')
                                  "
                                >
                                  {{
                                    'COMMON.WHITESPACE_CANT_CONTAIN_START_AND_END'
                                      | translate
                                  }}</mat-error
                                >
                              </mat-form-field>
                            </div>

                            <div *ngSwitchCase="dataType.NUMBER">
                              <mat-form-field
                                class="example-full-width form-input-field"
                              >
                                <input
                                  type="number"
                                  matInput
                                  formControlName="Value"
                                  [required]="field?.get('LabelId')?.value"
                                />
                              </mat-form-field>
                            </div>

                            <div *ngSwitchCase="dataType.DATE">
                              <mat-form-field
                                class="example-full-width form-input-field"
                              >
                                <input
                                  matInput
                                  [matDatepicker]="picker1"
                                  formControlName="Value"
                                  readonly
                                  [required]="field?.get('LabelId')?.value"
                                />
                                <mat-datepicker-toggle
                                  matIconSuffix
                                  [for]="picker1"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                            </div>

                            <div
                              *ngSwitchCase="dataType.BOOLEAN"
                              class="row mt-2 toggle-container"
                            >
                              <mat-slide-toggle
                                formControlName="Value"
                              ></mat-slide-toggle>
                            </div>
                          </ng-container>
                        </div>
                      </div>

                      <div class="col-md-2 mt-3">
                        <button
                          mat-mini-fab
                          color="warn"
                          aria-label="Example icon button with a delete icon"
                          (click)="onRemoveField(i)"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </tr>
                </table>
              </div>
              <div class="row" *ngIf="dynamicFields?.length == 0">
                <p>
                  {{ 'COMMON.DYNAMIC_FIELD_CONFIGURATION_HINT' | translate }}
                </p>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
</div>
