import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentUpload } from 'src/app/shared/models/document-upload.model';
import {
  ComposeEmail,
  OpenedClosedActivitiesCount,
} from '../models/account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountSharedService {
  private subjectNumberOfMail = new BehaviorSubject(null);
  private subjectOpenTaskAddModal = new BehaviorSubject(null);
  private subjectOpenCallAddModal = new BehaviorSubject(null);
  private subjectDraftTableChanged = new BehaviorSubject(null);
  private subjectNumberOfDraftMail = new BehaviorSubject(null);
  private subjectEmailTableChanged = new BehaviorSubject(null);
  private subjectOpenScheduleModal = new BehaviorSubject(null);
  private subjectAccountImportModal = new BehaviorSubject(null);
  private subjectCurrentAccountMail = new BehaviorSubject(null);
  private subjectOpenTaskTableChange = new BehaviorSubject(null);
  private subjectOpenCallTableChange = new BehaviorSubject(null);
  private subjectOpenMeetingAddModal = new BehaviorSubject(null);
  private subjectNumberOfScheduleMail = new BehaviorSubject(null);
  private subjectScheduledTableChanged = new BehaviorSubject(null);
  private subjectRemoveEmailDraftModal = new BehaviorSubject(null);
  private subjectNumberOfCanceledMails = new BehaviorSubject(null);
  private subjectEditContactsOpenModal = new BehaviorSubject(null);
  private subjectOpenMeetingTableChange = new BehaviorSubject(null);
  private subjectAccountListTableChanged = new BehaviorSubject(null);
  private subjectCancelEmailTableChanged = new BehaviorSubject(null);
  private subjectOpenComposeEmailAddModal = new BehaviorSubject(null);
  private subjectAccountDocAttachmentList = new BehaviorSubject(null);
  private subjectOpenComposeEmailEditModal = new BehaviorSubject(null);
  private subjectOpenedClosedActivitiesCount = new BehaviorSubject(null);
  private subjectAssignCampaginToAccountModal = new BehaviorSubject(null);
  private subjectNumberOfAssignCampaignToAccount = new BehaviorSubject(null);
  private subjectNumberOfAssignedContactsToAccount = new BehaviorSubject(null);

  private subjectAddExistingContactsModal = new BehaviorSubject(null);
  private subjectAccountContactLisChanged = new BehaviorSubject(null);

  constructor() {}

  setsubjectAccountDocAttachmentList(attachmentArray: DocumentUpload[]) {
    this.subjectAccountDocAttachmentList.next(attachmentArray);
  }
  getsubjectAccountDocAttachmentList(): Observable<DocumentUpload[]> {
    return this.subjectAccountDocAttachmentList.asObservable();
  }

  //Account View - open ativities - Task
  setsubjectOpenTaskAddModal(string: string) {
    this.subjectOpenTaskAddModal.next(string);
  }
  getsubjectOpenTaskAddModal(): Observable<any> {
    return this.subjectOpenTaskAddModal.asObservable();
  }

  //Account View - open ativities - Meeting
  setsubjectOpenMeetingAddModal(string: string) {
    this.subjectOpenMeetingAddModal.next(string);
  }
  getsubjectOpenMeetingAddModal(): Observable<any> {
    return this.subjectOpenMeetingAddModal.asObservable();
  }

  //call
  setSubjectOpenCallAddModal(string: string) {
    this.subjectOpenCallAddModal.next(string);
  }
  getSubjectOpenCallAddModal(): Observable<any> {
    return this.subjectOpenCallAddModal.asObservable();
  }

  // Account Open Activity List view
  getsubjectOpenTaskTableChange(): Observable<any> {
    return this.subjectOpenTaskTableChange.asObservable();
  }
  setsubjectOpenTaskTableChange(flag: boolean) {
    this.subjectOpenTaskTableChange.next(flag);
  }

  //Account Open Activity Meeting List view
  getsubjectOpenMeetingTableChange(): Observable<any> {
    return this.subjectOpenMeetingTableChange.asObservable();
  }
  setsubjectOpenMeetingTableChange(flag: boolean) {
    this.subjectOpenMeetingTableChange.next(flag);
  }

  //call
  getSubjectOpenCallTableChange(): Observable<any> {
    return this.subjectOpenCallTableChange.asObservable();
  }
  setSubjectOpenCallTableChange(flag: boolean) {
    this.subjectOpenCallTableChange.next(flag);
  }

  //Account Compose Email
  setsubjectOpenComposeEmailAddModal(string: string) {
    this.subjectOpenComposeEmailAddModal.next(string);
  }

  getsubjectOpenComposeEmailAddModal(): Observable<any> {
    return this.subjectOpenComposeEmailAddModal.asObservable();
  }

  setSubjectEmailTableChanged(flag: boolean) {
    return this.subjectEmailTableChanged.next(flag);
  }

  getSubjectEmailTableChanged(): Observable<any> {
    return this.subjectEmailTableChanged.asObservable();
  }

  setSubjectDraftTableChanged(flag: boolean) {
    return this.subjectDraftTableChanged.next(flag);
  }

  getSubjectDraftTableChanged(): Observable<any> {
    return this.subjectDraftTableChanged.asObservable();
  }

  setsubjectOpenComposeEmailEditModal(string: string) {
    this.subjectOpenComposeEmailEditModal.next(string);
  }

  getsubjectOpenComposeEmailEditModal(): Observable<any> {
    return this.subjectOpenComposeEmailEditModal.asObservable();
  }

  setSubjectOpenScheduleModal(obj: ComposeEmail) {
    this.subjectOpenScheduleModal.next(obj);
  }

  getSubjectOpenScheduleModal(): Observable<any> {
    return this.subjectOpenScheduleModal.asObservable();
  }

  setSubjectScheduledTableChanged(flag: boolean) {
    return this.subjectScheduledTableChanged.next(flag);
  }

  getSubjectScheduledTableChanged(): Observable<any> {
    return this.subjectScheduledTableChanged.asObservable();
  }

  setSubjectNumberOfMails(number: number) {
    return this.subjectNumberOfMail.next(number);
  }

  getSubjectNumberOfMails(): Observable<any> {
    return this.subjectNumberOfMail.asObservable();
  }

  setSubjectNumberOfDraftMails(number: number) {
    return this.subjectNumberOfDraftMail.next(number);
  }

  getSubjectNumberOfDraftMails(): Observable<any> {
    return this.subjectNumberOfDraftMail.asObservable();
  }

  setSubjectNumberOfScheduleMails(number: number) {
    return this.subjectNumberOfScheduleMail.next(number);
  }

  getSubjectNumberOfScheduleMails(): Observable<any> {
    return this.subjectNumberOfScheduleMail.asObservable();
  }

  setSubjectCurrentAccountMail(mail: string) {
    return this.subjectCurrentAccountMail.next(mail);
  }

  getSubjectCurrentAccountMail(): Observable<any> {
    return this.subjectCurrentAccountMail.asObservable();
  }

  setSubjectRemoveEmailDraftModal(flag: boolean) {
    this.subjectRemoveEmailDraftModal.next(flag);
  }
  getSubjectRemoveEmailDraftModal(): Observable<any> {
    return this.subjectRemoveEmailDraftModal.asObservable();
  }

  setSubjectAssignCampaginToAccountModal(str: string) {
    this.subjectAssignCampaginToAccountModal.next(str);
  }
  getSubjectAssignCampaginToAccountModal(): Observable<any> {
    return this.subjectAssignCampaginToAccountModal.asObservable();
  }

  setSubjectNumberOfAssignCampaignToAccount(number: number) {
    this.subjectNumberOfAssignCampaignToAccount.next(number);
  }
  getSubjectNumberOfAssignCampaignToAccount(): Observable<any> {
    return this.subjectNumberOfAssignCampaignToAccount.asObservable();
  }

  setSubjectNumberOfAssignedContactsToAccount(number: number) {
    this.subjectNumberOfAssignedContactsToAccount.next(number);
  }
  getSubjectNumberOfAssignedContactsToAccount(): Observable<any> {
    return this.subjectNumberOfAssignedContactsToAccount.asObservable();
  }

  setSubjectCancelEmailTableChanged(flag: boolean) {
    this.subjectCancelEmailTableChanged.next(flag);
  }
  getSubjectCancelEmailTableChanged(): Observable<any> {
    return this.subjectCancelEmailTableChanged.asObservable();
  }

  setSubjectNumberOfCanceledMails(count: number) {
    this.subjectNumberOfCanceledMails.next(count);
  }
  getSubjectNumberOfCanceledMails(): Observable<any> {
    return this.subjectNumberOfCanceledMails.asObservable();
  }

  // Opened Closed Activities Count
  setSubjectOpendClosedActivitiesCountChanged(
    count: OpenedClosedActivitiesCount
  ) {
    this.subjectOpenedClosedActivitiesCount.next(count);
  }
  getSubjectOpendClosedActivitiesCountChanged(): Observable<any> {
    return this.subjectOpenedClosedActivitiesCount.asObservable();
  }

  setSubjectEditContactsOpenModal(string: string) {
    this.subjectEditContactsOpenModal.next(string);
  }
  getSubjectEditContactsOpenModal(): Observable<any> {
    return this.subjectEditContactsOpenModal.asObservable();
  }

  setSubjectAccountImportModal(str: string) {
    this.subjectAccountImportModal.next(str);
  }
  getSubjectAccountImportModal(): Observable<any> {
    return this.subjectAccountImportModal.asObservable();
  }

  setSubjectAccountListTableChanged(flag: boolean) {
    this.subjectAccountListTableChanged.next(flag);
  }
  getSubjectAccountListTableChanged(): Observable<any> {
    return this.subjectAccountListTableChanged.asObservable();
  }

  setSubjectAddExistingContactsModal(flag: boolean) {
    this.subjectAddExistingContactsModal.next(flag);
  }
  getSubjectAddExistingContactsModal(): Observable<any> {
    return this.subjectAddExistingContactsModal.asObservable();
  }

  setSubjectAccountContactLisChanged(flag: boolean) {
    this.subjectAccountContactLisChanged.next(flag);
  }
  getSubjectAccountContactLisChanged(): Observable<any> {
    return this.subjectAccountContactLisChanged.asObservable();
  }
}
