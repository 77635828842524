import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { HelpersService } from '../../services/helpers.service';
import { Constants, Toast } from '../../data/constants';
import { ChangePassword } from '../../models/common-data.model';
import { ChangePasswordSharedService } from '../../services/change-password-shared.service';
import { ChangePasswordService } from '../../services/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent {
  @ViewChild('template', { static: true }) template: any;

  changePasswordDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  passwordForm: FormGroup;
  userName: string = '';
  hideNew: boolean = true;
  hideCurrent: boolean = true;
  hideConform: boolean = true;
  changePasswordReqBody: ChangePassword;
  isLoading: boolean = false;
  passwordPattern = Constants.PASSWORD_PATTERN;

  constructor(
    private changePasswordSharedService: ChangePasswordSharedService,
    private changePasswordService: ChangePasswordService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService
  ) {
    this.changePasswordDataSubject = changePasswordSharedService
      .getSubjectOpenChangePasswordModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: string) => {
        if (sortByOpen == 'Open') {
          this.showModal();
        }
      });
  }

  ngOnInit(): void {
    this.createChangePasswordForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-md',
    });
  }

  createChangePasswordForm() {
    this.passwordForm = this.fb.group({
      CurrentPassword: [
        '',
        [Validators.required, Validators.pattern(this.passwordPattern)],
      ],
      NewPassword: [
        '',
        [Validators.required, Validators.pattern(this.passwordPattern)],
      ],
      ConfirmPassword: [
        '',
        [
          Validators.required,
          this.helpersService.confirmPasswordValidator('NewPassword'),
          Validators.pattern(this.passwordPattern),
        ],
      ],
    });
  }

  resetPassword(formData: any) {
    this.isLoading = true;

    this.changePasswordReqBody = {
      currentPassword: formData.CurrentPassword,
      newPassword: formData.NewPassword,
      confirmPassword: formData.ConfirmPassword,
    };

    this.changePasswordService
      .changePassword(this.changePasswordReqBody)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          Toast.fire({
            icon: 'success',
            title: this.translateService.instant('COMMON.PASSWORD_CHANGE_MSG'),
          });
          this.cancelForm();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  cancelForm() {
    this.modalRef.hide();
    this.passwordForm.reset();
  }

  ngOnDestroy(): void {
    this.changePasswordSharedService.setSubjectOpenChangePasswordModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  changePassword() {
    this.passwordForm.get('ConfirmPassword').updateValueAndValidity();
  }
}
