import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickCreateSharedService {

  private subjectQuickCreateLeadOpenModal = new BehaviorSubject(null);
  private subjectChooseLeadListTableChanged = new BehaviorSubject(null);
  
  private subjectQuickCreateAccountOpenModal = new BehaviorSubject(null);
  private subjectChooseAccountListTableChanged = new BehaviorSubject(null);

  private subjectQuickCreateContactOpenModal = new BehaviorSubject(null);
  private subjectChooseContactListTableChanged = new BehaviorSubject(null);

  private subjectQuickCreateCampaignOpenModal = new BehaviorSubject(null);
  private subjectChooseCampaignListTableChanged = new BehaviorSubject(null);

  private subjectQuickCreateDealOpenModal = new BehaviorSubject(null);
  private subjectChooseDealListTableChanged = new BehaviorSubject(null);

  constructor() { }

  setSubjectQuickCreateLeadOpenModal(str: string) {
    this.subjectQuickCreateLeadOpenModal.next(str);
  }
  getSubjectQuickCreateLeadOpenModal(): Observable<any> {
    return this.subjectQuickCreateLeadOpenModal.asObservable();
  }
  setSubjectChooseLeadListTableChanged(flag: boolean) {
    this.subjectChooseLeadListTableChanged.next(flag);
  }
  getSubjectChooseLeadListTableChanged(): Observable<any> {
    return this.subjectChooseLeadListTableChanged.asObservable();
  }

  setSubjectQuickCreateCampaignOpenModal(str: string) {
    this.subjectQuickCreateCampaignOpenModal.next(str);
  }
  getSubjectQuickCreateCampaignOpenModal(): Observable<any> {
    return this.subjectQuickCreateCampaignOpenModal.asObservable();
  }
  setSubjectChooseCampaignListTableChanged(flag: boolean) {
    this.subjectChooseCampaignListTableChanged.next(flag);
  }
  getSubjectChooseCampaignListTableChanged(): Observable<any> {
    return this.subjectChooseCampaignListTableChanged.asObservable();
  }

  setSubjectQuickCreateContactOpenModal(str: string) {
    this.subjectQuickCreateContactOpenModal.next(str);
  }
  getSubjectQuickCreateContactOpenModal(): Observable<any> {
    return this.subjectQuickCreateContactOpenModal.asObservable();
  }

  setSubjectChooseContactListTableChanged(flag: boolean) {
    this.subjectChooseContactListTableChanged.next(flag);
  }
  getSubjectChooseContactListTableChanged(): Observable<any> {
    return this.subjectChooseContactListTableChanged.asObservable();
  }

  setSubjectQuickCreateDealOpenModal(str: string) {
    this.subjectQuickCreateDealOpenModal.next(str);
  }
  getSubjectQuickCreateDealOpenModal(): Observable<any> {
    return this.subjectQuickCreateDealOpenModal.asObservable();
  }

  setSubjectChooseDealListTableChanged(flag: boolean) {
    this.subjectChooseDealListTableChanged.next(flag);
  }
  getSubjectChooseDealListTableChanged(): Observable<any> {
    return this.subjectChooseDealListTableChanged.asObservable();
  }

  setSubjectQuickCreateAccountOpenModal(str: string) {
    this.subjectQuickCreateAccountOpenModal.next(str);
  }
  getSubjectQuickCreateAccountOpenModal(): Observable<any> {
    return this.subjectQuickCreateAccountOpenModal.asObservable();
  }

  setSubjectChooseAccountListTableChanged(flag: boolean) {
    this.subjectChooseAccountListTableChanged.next(flag);
  }
  getSubjectChooseAccountListTableChanged(): Observable<any> {
    return this.subjectChooseAccountListTableChanged.asObservable();
  }
}
