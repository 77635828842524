import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealAssignmentSharedService {
  private subjectEditDealsOpenModal = new BehaviorSubject(null);
  private subjectNumberOfAssignExistingDeals = new BehaviorSubject(null);
  private subjectAssignExistingDealsOpenModal = new BehaviorSubject(null);
  private subjectAssignExistingDealsTableChanged = new BehaviorSubject(null);

  constructor() {}

  setSubjectAssignExistingDealsOpenModal(str: string) {
    this.subjectAssignExistingDealsOpenModal.next(str);
  }
  getSubjectAssignExistingDealsOpenModal(): Observable<any> {
    return this.subjectAssignExistingDealsOpenModal.asObservable();
  }

  setSubjectEditDealsOpenModal(string: string) {
    this.subjectEditDealsOpenModal.next(string);
  }
  getSubjectEditDealsOpenModal(): Observable<any> {
    return this.subjectEditDealsOpenModal.asObservable();
  }

  setSubjectAssignExistingDealsTableChanged(flag: boolean) {
    this.subjectAssignExistingDealsTableChanged.next(flag);
  }
  getSubjectAssignExistingDealsTableChanged(): Observable<any> {
    return this.subjectAssignExistingDealsTableChanged.asObservable();
  }

  setSubjectNumberOfAssignExistingDeals(number: number) {
    this.subjectNumberOfAssignExistingDeals.next(number);
  }
  getSubjectNumberOfAssignExistingDeals(): Observable<any> {
    return this.subjectNumberOfAssignExistingDeals.asObservable();
  }
}
