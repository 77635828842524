import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApprovalWorkflowListSharedService {
  private subjectOpenReviewConvertToLeadModal = new BehaviorSubject(null);
  private subjectOpenTaskActionViewModal = new BehaviorSubject(null);
  private subjectShowTaskHistoryTable = new BehaviorSubject(null);
  private subjectOpenReviewConvertToDealModal = new BehaviorSubject(null);
  private subjectPendingApprovalWorkflowTableChanged = new BehaviorSubject(null);
  private subjectHideTaskHistoryTable = new BehaviorSubject(null);

  setSubjectOpenReviewConvertToLeadModal(obj: any) {
    this.subjectOpenReviewConvertToLeadModal.next(obj);
  }

  getSubjectOpenReviewConvertToLeadModal(): Observable<any> {
    return this.subjectOpenReviewConvertToLeadModal.asObservable();
  }

  setSubjectOpenTaskActionViewModal(obj: any) {
    this.subjectOpenTaskActionViewModal.next(obj);
  }

  getSubjectOpenTaskActionViewModal(): Observable<any> {
    return this.subjectOpenTaskActionViewModal.asObservable();
  }

  setSubjectShowTaskHistoryTable(id: string) {
    this.subjectShowTaskHistoryTable.next(id);
  }

  getSubjectShowTaskHistoryTable(): Observable<string> {
    return this.subjectShowTaskHistoryTable.asObservable();
  }

  setSubjectOpenReviewConvertToDealModal(obj: any) {
    this.subjectOpenReviewConvertToDealModal.next(obj);
  }

  getSubjectOpenReviewConvertToDealModal(): Observable<any> {
    return this.subjectOpenReviewConvertToDealModal.asObservable();
  }

  setSubjectPendingApprovalWorkflowTableChanged(obj: any) {
    this.subjectPendingApprovalWorkflowTableChanged.next(obj);
  }

  getSubjectPendingApprovalWorkflowTableChanged(): Observable<any> {
    return this.subjectPendingApprovalWorkflowTableChanged.asObservable();
  }

  setSubjectHideTaskHistoryTable(flag: boolean) {
    this.subjectHideTaskHistoryTable.next(flag);
  }

  getSubjectHideTaskHistoryTable(): Observable<any> {
    return this.subjectHideTaskHistoryTable.asObservable();
  }
}
