<div class="row mt-3 flex-row-reverse">
  <div
    class="col-xl-2 col-lg-2 col-md-4 col-sm-12 mb-2 d-flex flex-row-reverse justify-content-between align-items-baseline filter-section-mg"
  >
    <button
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon"
      matTooltip="Clear filters"
      class="d-flex justify-content-center align-items-center"
      (click)="resetFilter()"
      *ngIf="enableReset && isShowFilter"
    >
      <i class="material-icons-outlined filter-btn-icon icon-font-size"
        >restart_alt</i
      >
    </button>
    <button
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon"
      matTooltip="Filters"
      class="d-flex justify-content-center align-items-center {{
        fileterValues?.FilterValues?.length > 0 ? 'icon-selcected' : ''
      }}"
      (click)="openParameterModal()"
    >
      <i class="material-icons-outlined filter-btn-icon icon-font-size"
        >filter_alt</i
      >
    </button>
    <button
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon"
      matTooltip="Columns"
      class="d-flex justify-content-center align-items-center"
      (click)="openColoumnModal()"
      *ngIf="isShowColumns"
    >
      <i class="material-icons-outlined filter-btn-icon icon-font-size"
        >view_column</i
      >
    </button>
    <button
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon "
      matTooltip="Sort By"
      class="d-flex justify-content-center align-items-center  {{
        isSortByEnabled(this.fileterValues) ? 'icon-selcected' : ''
      }}"
      (click)="openSortByModal()"
      *ngIf="isShowSortBy"
    >
      <i class="material-icons-outlined filter-btn-icon icon-font-size"
        >sort_by_alpha</i
      >
    </button>

    <button
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon"
      matTooltip="Group By"
      class="d-flex justify-content-center align-items-center {{
        this.fileterValues?.groupBy != null ? 'icon-selcected' : ''
      }} "
      (click)="openGroupByModal()"
      *ngIf="isShowGroupBy"
    >
      <i class="material-icons-outlined filter-btn-icon icon-font-size"
        >table_rows</i
      >
    </button>
    <form [formGroup]="filterForm">
      <mat-form-field class="example-full-width search-input" appearance="">
        <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
        <input
          matInput
          formControlName="search"
          (input)="searchInputChange()"
        />
        <mat-icon matSuffix class="face-icon p-0">search</mat-icon>
      </mat-form-field>
    </form>
  </div>
</div>

<app-filter-sorting [filterForm]="filterForm"></app-filter-sorting>
<app-filter-column [filterForm]="filterForm"></app-filter-column>
<app-filter-group [filterForm]="filterForm"></app-filter-group>
<app-filter-parameters [filterForm]="filterForm"></app-filter-parameters>
