import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() progress;
  @Input() headerName;
  @Input() isBtnShow;
  @Input() isPdfTxtShow = true;
  onChange: Function;
  public file: File | null = null;

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    const file = event && event.item(0);

    if (this.checkFileType(file?.type) || this.checkFileSize(file?.size)) {
      this.file = null;
    } else {
      this.file = file;
    }
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}
  btnTriggerClick() {
    let fileInput = document.getElementById('file-input');
    fileInput?.click();
  }

  checkFileType(fileType: any) {
    if (
      fileType.includes('png') ||
      fileType.includes('jpg') ||
      fileType.includes('pdf') ||
      fileType.includes('jpeg')
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkFileSize(fileSize: any) {
    if (fileSize < 2097152) {
      //2097152 byte = 2MB
      return false;
    } else {
      return true;
    }
  }
}
