import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { NotificationModel } from '../../shared/models/common-data.model';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubConnection: signalR.HubConnection;
  private messageSubject: Subject<any> = new Subject<any>();
  constructor(private authService: AuthService) {}

  public connect(): void {
    this.hubConnection = new signalR.HubConnectionBuilder()

      .withUrl(environment.signalRUrl)

      .build();

    this.hubConnection.on(
      'ReceiveNotification',
      (message: NotificationModel) => {
        this.messageSubject.next(message);
      }
    );

    this.hubConnection
      .start()
      .then(() => {
        this.joinGroup(
          'USER_' +
            this.authService.currentUserValue.id +
            '_COMPANY_' +
            this.authService.getCurrentCompanyValue.id
        );
        this.joinGroup('USER_' + this.authService.currentUserValue.id);
        this.joinGroup('COMPANY_' + this.authService.getCurrentCompanyValue.id);
      })
      .catch((error) => {
      });
  }

  private joinGroup(groupName: string): void {
    if (this.hubConnection) {
      this.hubConnection
        .invoke('AddToGroup', groupName)
        .then(() => {
          // console.log(`Joined group: ${groupName}`);
        })
        .catch((error) => {
          // console.log('Error joining group:', error);
        });
    }
  }

  public disconnect(): void {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => {
          // console.log('Hub connection stopped');
        })
        .catch((error) => {
          // console.log('Error stopping hub connection:', error);
        });
    }
  }

  public sendMessage(message: string): void {
    if (this.hubConnection) {
      this.hubConnection
        .invoke('SendNotification', message)
        .then(() => {
          // console.log('Message sent:', message);
        })
        .catch((error) => {
          // console.log('Error sending message:', error);
        });
    }
  }

  public getMessageObservable(): Observable<NotificationModel> {
    return this.messageSubject.asObservable();
  }
}
