import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { Role } from 'src/app/core/models/role';
import { AuthService } from 'src/app/core/service/auth.service';
import { RouteInfo, RouteInfoSide } from './sidebar.metadata';
import { SidebarSharedService } from '../sidebar/services/sidebar-shared.service';
import { CRM_ADMIN_ROLE } from 'src/app/shared/data/constants';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  routeInfoSide: RouteInfoSide;
  isNavbarCollapsed = false;

  isManualSidebarClosed: string = 'close';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private sideBarSharedService: SidebarSharedService
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }

  callToggleMenuModal(event: any, sidemenu: RouteInfo[], parent, icon) {
    this.sideBarSharedService.setsubjectSideMenuData(null);
    this.sideBarSharedService.setsubjectSideMenuOpen('close');
    // if (this.isManualSidebarClosed=="open") {
    //   this.isManualSidebarClosed="close"
    // }else{

    //   this.isManualSidebarClosed="open"
    // }
    if (sidemenu.length > 0) {
      this.routeInfoSide = {
        parent: parent,
        parentIcon: icon,
        submenu: sidemenu,
      };
      this.sideBarSharedService.setsubjectSideMenuData(this.routeInfoSide);
      this.sideBarSharedService.setsubjectSideMenuOpen('open');
    }
  }

  ngOnInit() {
    this.darkSidebarBtnClick();

    this.sidebarItems = ROUTES;
    this.sideBarSharedService.setsubjectSideMenuOpen('close');

    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      this.userFullName =
        this.authService.currentUserValue.firstName +
        ' ' +
        this.authService.currentUserValue.lastName;
      this.userImg = this.authService.currentUserValue.img;

      this.sidebarItems = [];
      this.sortMenuByRoles();
    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  sortMenuByRoles() {
    var roles = this.authService.currentUserValue.role;
    this.sidebarItems = ROUTES;

    if (!this.authService.currentUserValue.role.includes(CRM_ADMIN_ROLE)) {
      this.sidebarItems = [ROUTES[0], ...ROUTES.slice(1).filter(
        (x) =>
          x.role.some((r) => roles.indexOf(r) !== -1) ||
          x.role.some((r) => roles.indexOf('All') !== -1)
      )];

      var menuList = [];

      for (let index = 0; index < this.sidebarItems.length; index++) {
        const element = this.sidebarItems[index];
        element.submenu = element.submenu.filter(
          (x) =>
            x.role.some((r) => roles.indexOf(r) !== -1) ||
            x.role.some((r) => roles.indexOf('All') !== -1) ||
            x.role.some((r) => roles.indexOf('Admin') !== -1)
        );
        menuList.push(element);
      }
      this.sidebarItems = menuList;
    }
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  darkSidebarBtnClick() {
    this.renderer.addClass(this.document.body, 'side-closed');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_light');
    this.renderer.removeClass(this.document.body, 'logo-white');
    this.renderer.addClass(this.document.body, 'menu_dark');
    this.renderer.addClass(this.document.body, 'logo-black');
    const menuOption = 'menu_dark';
    localStorage.setItem('choose_logoheader', 'logo-black');
    localStorage.setItem('menuOption', menuOption);
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.isNavbarCollapsed = false;
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.isNavbarCollapsed = true;
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
}
