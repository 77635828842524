<ng-template #template>
  <div class="row block-bg">
    <div class="row">
      <div class="col-md-4 d-flex align-items-center mt-4">
        &nbsp;
        <mat-icon class="mb-2" (click)="backClick()">arrow_back</mat-icon>
        &nbsp;&nbsp;&nbsp;
        <h5>
          <b>{{ 'DEALS_MENU.DEAL_INFORMATION' | translate }}</b>
        </h5>
      </div>
      <div class="col-md-8 d-flex flex-row-reverse align-items-center mt-4">
        <button
          (click)="editDeal(dealForm?.value, approvalStatus.APPROVED)"
          mat-flat-button
          class="flat-info-btn btn-text-size"
          color="primary"
          [disabled]="!dealForm?.valid || emailExists"
          *ngIf="!isLoading"
        >
          <mat-icon>save</mat-icon> {{ 'COMMON.SAVE' | translate }}
        </button>
        <button
          mat-flat-button
          class="flat-info-btn"
          color="primary"
          *ngIf="isLoading"
        >
          <mat-spinner diameter="20"></mat-spinner>
        </button>
        &nbsp;&nbsp;
        <button
          mat-stroked-button
          color="primary"
          class="btn-border outline-info-btn"
          (click)="backClick()"
        >
          {{ 'COMMON.CANCEL' | translate }}
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
    <form [formGroup]="dealForm">
      <div class="row m-4">
        <div class="col-md-6">
          <mat-form-field
            class="example-full-width form-input-field form-input-field-readonly"
          >
            <mat-label>{{ 'DEALS_MENU.DEAL_NO' | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="DealNo"
              readonly
              required
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.DEAL_OWNER' | translate }}</mat-label>
            <mat-select formControlName="DealOwnerId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="userSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                  ></ngx-mat-select-search
                >
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let user of usersList | filter : userSearch"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.AMOUNT' | translate }}</mat-label>
            <input
              type="number"
              matInput
              formControlName="Amount"
              required
              pattern="^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$"
              (keypress)="helpersService.preventE($event)"
            />
            <mat-error *ngIf="dealForm?.get('Amount').hasError('pattern')">
              {{
                'COMMON.AMOUNT_SHOULD_BE_GREATER_THAN_ZERO' | translate
              }}</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.DEAL_NAME' | translate }}</mat-label>
            <input type="text" matInput formControlName="DealName" required />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.CLOSING_DATE' | translate }}</mat-label>
            <input
              formControlName="ClosingDate"
              matInput
              [matDatepicker]="picker1"
              readonly
              required
              [min]="minDate"
              (dateChange)="expiryDateChanged($event)"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.EMAIL' | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Email"
              required
              (focusout)="emailFocusOut($event)"
            />
            <mat-error *ngIf="dealForm.get('Email').hasError('pattern')">
              Please enter a valid email address.
            </mat-error>
            <mat-hint *ngIf="emailExists" class="email-exist">
              {{ 'ADMINISTRATION.EMAIL_EXISTS' | translate }}</mat-hint
            >
            <mat-hint *ngIf="!emailExists">{{
              'COMMON.EG_example123@gmail.com' | translate
            }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.STAGE' | translate }}</mat-label>
            <mat-select formControlName="DealStageId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="dealStageSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let dealStage of dealStageList | filter : dealStageSearch
                "
                [value]="dealStage.id"
                >{{ dealStage.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.ACCOUNT_NAME' | translate }}</mat-label>
            <mat-select formControlName="AccountId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="accountSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="null">-None-</mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let account of accountList | filter : accountSearch"
                [value]="account?.id"
              >
                {{ account?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.TYPE' | translate }}</mat-label>
            <mat-select formControlName="DealTypeId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="dealTypeSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let dealType of dealTypeList | filter : dealTypeSearch"
                [value]="dealType.id"
                >{{ dealType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.PROBABILITY' | translate }}</mat-label>
            <input
              type="number"
              matInput
              formControlName="Probability"
              [min]="0"
              (keypress)="helpersService.preventE($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.NEXT_STEP' | translate }}</mat-label>
            <input type="text" matInput formControlName="NextStep" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'DEALS_MENU.EXPECTED_REVENUE' | translate
            }}</mat-label>
            <input
              type="number"
              matInput
              formControlName="ExpectedRevenue"
              [min]="0"
              (keypress)="helpersService.preventE($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.LEAD_SOURCE' | translate }}</mat-label>
            <mat-select formControlName="LeadSourceId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="leadSourceSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let leadSource of leadSourceList | filter : leadSourceSearch
                "
                [value]="leadSource.id"
                >{{ leadSource.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'DEALS_MENU.CAMPAIGN_SOURCE' | translate
            }}</mat-label>
            <mat-select formControlName="CampaignSourceId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="campaignSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let campaign of campaignList | filter : campaignSearch"
                [value]="campaign?.id"
                >{{ campaign?.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.CONTACT_NAME' | translate }}</mat-label>
            <mat-select formControlName="ContactId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="contactSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="null">-None-</mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let contact of contactList | filter : contactSearch"
                [value]="contact?.id"
                >{{ contact?.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'COMMON.PLEASE_LET_US_KNOW_IF_YOU_ARE_EXPATRIATE_OR_OMANI'
                | translate
            }}</mat-label>
            <mat-select formControlName="ApplicantClassificationId">
              <mat-option
                class="form-select-option"
                *ngFor="let applicant of applicantClassificationList"
                [value]="applicant.id"
                >{{ applicant.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.PROGRAMME_LEVEL' | translate }}</mat-label>
            <mat-select formControlName="ProgrammeLevelId">
              <mat-option
                class="form-select-option"
                *ngFor="let level of programmeLevelsList"
                [value]="level.id"
                >{{ level.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.BACHELOR_PROGRAMME' | translate }}</mat-label>
            <mat-select formControlName="BachelorProgrammeId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="bachelorProgrammeSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let bachelor of bachelorProgrammeList
                    | filter : bachelorProgrammeSearch
                "
                [value]="bachelor.id"
                >{{ bachelor.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.MASTER_PROGRAMME' | translate }}</mat-label>
            <mat-select formControlName="MasterProgrammeId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="masterProgrammeSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let master of masterProgrammeList
                    | filter : masterProgrammeSearch
                "
                [value]="master.id"
                >{{ master.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'COMMON.HOW_DID_YOU_HEAR_ABOUT_US' | translate
            }}</mat-label>
            <mat-select formControlName="HowDidHear">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="mediaListSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let media of howDidYouHearAboutUsList
                    | filter : mediaListSearch
                "
                [value]="media.id"
                >{{ media.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.DESCRIPTION' | translate }}</mat-label>
            <textarea
              type="text"
              matInput
              formControlName="Description"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>
