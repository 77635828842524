import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { FilterData } from '../../models/filter-data.model';
import { FilterSharedService } from '../../services/filter-shared.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnDestroy {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  @Input() filterForm: FormGroup;
  @Input() isShowColumns: boolean = true;
  @Input() isShowGroupBy: boolean = true;
  @Input() isShowSortBy: boolean = true;
  @Input() isShowFilter: boolean = true;

  fileterValues: any;

  enableReset: boolean = false;

  constructor(
    private filterSharedService: FilterSharedService,
    private fb: FormBuilder
  ) {
    this.filterDataSubject = filterSharedService
      .getsubjectFilterData()
      .pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;
        }
      });
  }

  ngOnInit() {
    this.initializeForm();
    this.getValueChanges();
  }

  initializeForm() {
    this.filterForm?.addControl('search', new FormControl());
  }

  openColoumnModal() {
    this.filterSharedService.setsubjectColumnOpenModal('open');
  }

  openParameterModal() {
    this.filterSharedService.setsubjectFilterParameterOpenModal('open');
  }

  openSortByModal() {
    this.filterSharedService.setsubjectSortByOpenModal('open');
  }

  openGroupByModal() {
    this.filterSharedService.setsubjectgroupOpenModal('open');
  }

  onSearchChange(event: any) {
    console.log(event);
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.filterSharedService.setsubjectSortByOpenModal(null);
    this.filterSharedService.setsubjectFilterData(null);
    this.filterSharedService.setsubjectColumnOpenModal(null);
    this.filterSharedService.setsubjectgroupOpenModal(null);
    this.filterSharedService.setsubjectFilterParameterOpenModal(null);
  }

  resetFilter() {
    this.filterForm?.patchValue({
      // search: "",
      groupBy: null,
    });
    this.filterSharedService.setsubjectFilterReset(true);
    this.filterSharedService.setsubjectApplyFilter(true);

    this.enableReset = false;
  }

  isSortByEnabled(values) {
    var flag = false;

    flag =
      values?.SortByArr.filter((x) => {
        return x.IsActive;
      }).length > 0;
    return flag;
  }

  getValueChanges() {
    this.filterForm?.valueChanges.subscribe((searchformValues) => {
      this.fileterValues = searchformValues;
      var sortByenabled = searchformValues.SortByArr.filter((x) => {
        return x.IsActive;
      });

      if (
        searchformValues?.groupBy != null ||
        searchformValues?.FilterValues?.length > 0 ||
        sortByenabled?.length > 0
      ) {
        this.enableReset = true;
      } else {
        this.enableReset = false;
      }
    });
  }

  searchInputChange() {
    this.filterSharedService.setsubjectApplyFilter(true);
  }
}
