import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { DocumentUpload } from 'src/app/shared/models/document-upload.model';
import {
  LeadReq,
  Note,
  ActivityTask,
  OpenActivityTask,
  ComposeEmail,
  CallLog,
  OpenActivityMeeting,
  ConvertLead,
  QuickCampaign,
  LeadPaginated,
  LeadGroupedList,
  OpenedClosedActivitiesCount,
  OpenActivityCall,
} from 'src/app/home/leads/models/leads.model';
import { KanbanStatus } from 'src/app/shared/models/common-data.model';
@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  private apiTask = 'api/Task';
  private apiCall = 'api/Call';
  private apiEmail = 'api/Email';
  private apiNoteUrl = 'api/Note';
  private apiLeadsUrl = 'api/Leads';
  private apiMeeting = 'api/Meeting';
  private apiCampaignUrl = 'api/Campaign';
  private apiAttachmentUrl = 'api/Attachment';

  constructor(public baseDataService: BaseDataService) {}

  getLeadList(
    filter: BaseFilterVm
  ): Observable<LeadPaginated | LeadGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiLeadsUrl}/getAllLeads`,
      filter
    );
  }

  createLead(lead: LeadReq): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiLeadsUrl}`, lead);
  }

  editLead(lead: LeadReq): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiLeadsUrl}`, lead);
  }

  deleteLead(leadId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiLeadsUrl}/${leadId}`);
  }

  bulkLeadsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiLeadsUrl}/deleteRangeLeadsAsync`,
      ids
    );
  }

  getLead(id: string): Observable<LeadReq> {
    return this.baseDataService.makeGetCall(`${this.apiLeadsUrl}?leadId=${id}`);
  }

  cloneLead(leadId: any): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiLeadsUrl}/clone?leadId=${leadId}`
    );
  }

  convertToLead(lead: LeadReq): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiLeadsUrl}/convertToLead`,
      lead
    );
  }

  // Note API endpoints
  createNote(note: Note): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiNoteUrl}`, note);
  }

  // getAllNotes(id: string): Observable<Note[]> {
  //   return this.baseDataService.makeGetCall(
  //     `${this.apiNoteUrl}/getAllNotesByReferenceId?refereceId=${id}`
  //   );
  // }

  deleteNote(noteId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiNoteUrl}/${noteId}`);
  }

  editNote(note: Note): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiNoteUrl}`, note);
  }

  // Attachment API endpoints
  createAttachment(documentUpload: DocumentUpload): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiAttachmentUrl}`,
      documentUpload
    );
  }

  getAllAttachment(id: string): Observable<DocumentUpload[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiAttachmentUrl}/getAttachmentsByReferenceId?refereceId=${id}`
    );
  }

  deleteAttachment(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiAttachmentUrl}/${id}`
    );
  }

  editAttachment(documentUpload: DocumentUpload): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiAttachmentUrl}`,
      documentUpload
    );
  }

  getLeadNumber(): Observable<any> {
    return this.baseDataService.makeGetCall(
      `${this.apiLeadsUrl}/getLeadNumber`
    );
  }

  //Task API endpoints
  createTask(task: ActivityTask): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiTask}`, task);
  }

  getTaskList(filter: BaseFilterVm): Observable<OpenActivityTask[]> {
    return this.baseDataService.makePostCall(
      `${this.apiTask}/getAlltasks`,
      filter
    );
  }

  getTasksByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityTask[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getTasksByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  //Email API endpoints
  createEmail(email: ComposeEmail): Observable<ComposeEmail> {
    return this.baseDataService.makePostCall(`${this.apiEmail}`, email);
  }

  getAllEmails(
    emailStatus: string,
    referenceId: string
  ): Observable<ComposeEmail[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiEmail}/getAllEmailsByReferenceId?emailStatus=${emailStatus}&referenceId=${referenceId}`
    );
  }

  getAllEmailsByReferenceId(reqObj: any): Observable<ComposeEmail[]> {
    return this.baseDataService.makePostCall(
      `${this.apiEmail}/getAllEmailsByReferenceId`,
      reqObj
    );
  }

  deleteDraftMail(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiEmail}/${id}`);
  }

  getEmailById(id: string): Observable<ComposeEmail> {
    return this.baseDataService.makeGetCall(`${this.apiEmail}?emailId=${id}`);
  }

  updateEmail(email: ComposeEmail): Observable<ComposeEmail> {
    return this.baseDataService.makePutCall(`${this.apiEmail}`, email);
  }

  getDraftScheduleEmailsByReferenceId(
    emailStatus: string,
    referenceId: string
  ): Observable<ComposeEmail[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiEmail}/getDraftScheduleEmailsByReferenceId?emailStatus=${emailStatus}&referenceId=${referenceId}`
    );
  }

  //call log API endpoints
  createCallLog(callLog: CallLog): Observable<CallLog> {
    return this.baseDataService.makePostCall(`${this.apiCall}`, callLog);
  }
  getAllCallLogs(filter: BaseFilterVm): Observable<CallLog[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCall}/getAllCalls`,
      filter
    );
  }

  getAllCallLogsByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityCall[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCall}/getCallsByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  //meeting API endpoints
  getAllMeetings(filter: BaseFilterVm): Observable<OpenActivityMeeting[]> {
    return this.baseDataService.makePostCall(
      `${this.apiMeeting}/getAllMeetings`,
      filter
    );
  }

  getAllMeetingsByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityMeeting[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiMeeting}/getMeetingsByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  createMeetings(
    openActivityMetting: OpenActivityMeeting
  ): Observable<OpenActivityMeeting> {
    return this.baseDataService.makePostCall(
      `${this.apiMeeting}`,
      openActivityMetting
    );
  }

  convertLead(
    convertLead: ConvertLead,
    leadId: string,
    isConverToDeal: boolean
  ): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiLeadsUrl}/convertLead?leadId=${leadId}&isConvertToDeal=${isConverToDeal}`,
      convertLead
    );
  }

  convertSummary(leadId: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiLeadsUrl}/convertSummary?leadId=${leadId}`,
      ''
    );
  }

  quickCreateCampaign(quickCampign: QuickCampaign): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/quickCreateCampaign`,
      quickCampign
    );
  }

  leadExcelExport(filter: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiLeadsUrl}/leadExcelExport`,
      filter
    );
  }

  leadTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiLeadsUrl}/leadTemplateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  leadUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiLeadsUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }

  cancelScheduledEmail(id: string): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiEmail}/cancelScheduledEmail?emailId=${id}`,
      ''
    );
  }

  isLeadEmailExists(id: string | null, email: string): Observable<any> {
    var params = id ? 'id=' + id + '&email=' + email : 'email=' + email;
    return this.baseDataService.makeGetCall(
      `${this.apiLeadsUrl}/isLeadEmailExists?${params}`
    );
  }

  // Opened Closed Activities Count
  getOpenedClosedActivitiesByReferenceId(
    id: string,
    module: string
  ): Observable<OpenedClosedActivitiesCount> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getOpenedClosedActivitiesByReferenceId?referenceId=${id}&module=${module}`
    );
  }

  updateLeadKanbanStatus(kanbanStatus: KanbanStatus): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiLeadsUrl}/updateLeadKanbanStatus`, kanbanStatus);
  }
}
