import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import jwt_decode from 'jwt-decode';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string;
  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private helpersService: HelpersService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    this.token = currentUser?.token;

    // if (currentUser && currentUser.token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${currentUser.token}`,
    //     },
    //   });
    // }

    // return next.handle(request);

    if (currentUser && currentUser.token && !request.url.includes('realm')) {
      if (
        this.isTokenExpired(currentUser?.token) && !this.router.url.includes('signin')
      ) {
        this.authenticationService.setRefreshToken(currentUser.refresh_token);
        this.refreshToken().subscribe((x) => {
          this.assignToken(x);

          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.token}`,
            },
          });
        });
      }

      if (
        this.isTokenExpired(currentUser?.token)
      ) {
        this.authenticationService.logout().subscribe((res) => {
          location.reload();
        });
        this.helpersService.showErrorToast('Your session has expired');
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
          },
        });
      }
    }

    return next.handle(request);
  }

  isTokenExpired(token: string): boolean {
    try {
      const decodedToken: any = jwt_decode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if(decodedToken.exp < currentTime) {
        return true
      } else {
        return false
      }
      // return decodedToken.exp < currentTime;
    } catch (error) {
      // Error decoding token
      return true;
    }
  }

  assignToken(res) {
    const token_data: any = jwt_decode(res.access_token);

    var roles = token_data?.realm_access?.roles ?? [];

    var user: User = {
      firstName: token_data.given_name,
      lastName: token_data.family_name,
      img: '',
      id: token_data.sub,
      password: '',
      role: roles,
      username: token_data.preferred_username,
      name: token_data.name,
      token: res.access_token,
      email: token_data.email,
      refresh_token: res.refresh_token,
    };

    localStorage.setItem('currentUser', JSON.stringify(user));

    // this.authenticationService.currentUserSubject.next(user);
  }

  refreshToken(): Observable<any> {
    return this.authenticationService.getRefreshToken();
  }
}
