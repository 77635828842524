<div>
  <small *ngIf="!headerName">{{
    'COMMON_DOCUMENT_MENU.PHOTO' | translate
  }}</small>
  <small *ngIf="headerName">{{ headerName | translate }}</small>
  <div class="row">
    <div
      class="col-md-3 flex-fill d-flex justify-content-center align-items-center"
      *ngIf="isBtnShow"
    >
      <button
        *ngIf="isBtnShow"
        mat-raised-button
        class="flat-info-btn"
        color="primary"
        (click)="btnTriggerClick()"
      >
        <span class="text-container">{{
          'COMMON_DOCUMENT_MENU.CHOOSE_FILE' | translate
        }}</span>
      </button>
    </div>
    <div class="file-drop-area">
      <div
        class="col-md-9 flex-fill file-input-container d-flex justify-content-center align-items-center"
        [ngClass]="isBtnShow == true ? 'col-md-9' : 'col-md-12'"
      >
        <span class="d-flex align-items-center"
          ><i class="material-icons-two-tone filter-btn-icon icon-font-size"
            >attach_file</i
          >&nbsp;&nbsp;{{
            file
              ? file.name
              : ('COMMON_DOCUMENT_MENU.DRAG_AND_DROP_COPY_AND_PASTE_FILE_HERE'
                | translate)
          }}</span
        >
        <input
          class="file-input"
          id="file-input"
          placeholder="No File Selected"
          type="file"
        />
      </div>
    </div>
  </div>
  <mat-hint>
    {{ 'COMMON.POSSIBLE_FILE_TYPES_ARE' | translate }}
    <span *ngIf="isPdfTxtShow"
      ><b>{{ 'COMMON.PDF' | translate }}</b
      >,
    </span>
    <b>{{ 'COMMON.JPG' | translate }}</b> {{ 'COMMON.AND' | translate }}
    <b>{{ 'COMMON.PNG' | translate }}</b
    >. {{ 'COMMON.MAXIMUM_FILE_SIZE_IS' | translate }} <b>2MB</b></mat-hint
  >
</div>
