import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { ResetPasswordService } from '../../services/reset-password.service';
import { ResetPasswordSharedService } from '../../services/reset-password-shared.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '../../services/helpers.service';
import { Constants, Toast } from '../../data/constants';
import { ResetPassword } from '../../models/common-data.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  @ViewChild('template', { static: true }) template: any;

  resetPasswordDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  resetPasswordForm: FormGroup;
  userName: string = '';
  hide: boolean = true;
  hideConform: boolean = true;
  resetPasswordReqBody: ResetPassword;
  isLoading: boolean = false;
  passwordPattern = Constants.PASSWORD_PATTERN;

  constructor(
    private resetPasswordSharedService: ResetPasswordSharedService,
    private resetPasswordService: ResetPasswordService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService
  ) {
    this.resetPasswordDataSubject = resetPasswordSharedService
      .getSubjectOpenResetPasswordModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((userName: string) => {
        if (userName) {
          this.userName = userName;
          this.showModal();
        }
      });
  }

  ngOnInit(): void {
    this.createChangePasswordForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-md',
    });
  }

  createChangePasswordForm() {
    this.resetPasswordForm = this.fb.group({
      Password: [
        '',
        [Validators.required, Validators.pattern(this.passwordPattern)],
      ],
      ConfirmPassword: [
        '',
        [
          Validators.required,
          this.helpersService.confirmPasswordValidator('Password'),
          Validators.pattern(this.passwordPattern),
        ],
      ],
    });
  }

  resetPassword(formData: any) {
    this.isLoading = true;

    this.resetPasswordReqBody = {
      username: this.userName,
      password: formData.Password,
      confirmPassword: formData.ConfirmPassword,
    };

    this.resetPasswordService
      .resetPassword(this.resetPasswordReqBody)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          Toast.fire({
            icon: 'success',
            title: this.translateService.instant(
              'ADMINISTRATION.PASSWORD_CHANGED_SUCCESSFULLY'
            ),
          });
          this.cancelForm();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  cancelForm() {
    this.modalRef.hide();
    this.resetPasswordForm.reset();
  }

  ngOnDestroy(): void {
    this.resetPasswordSharedService.setSubjectOpenResetPasswordModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  changePassword() {
    this.resetPasswordForm.get('ConfirmPassword').updateValueAndValidity();
  }
}
