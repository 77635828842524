import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OpenActivityCall, OpenActivityMeeting } from '../models/deal-view.model';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { OpenActivityTask } from 'src/app/home/leads/models/leads.model';
import { DocumentUpload } from 'src/app/shared/models/document-upload.model';
import {
  Account,
  ActivityTask,
  CallLog,
  Campaign,
  ComposeEmail,
  Contact,
  DealNote,
  DealStageVerifyDetail,
  DealStatusDetail,
  DealView,
  OpenedClosedActivitiesCount
} from '../models/deal-view.model';

@Injectable({
  providedIn: 'root',
})
export class DealViewService {
  private apiCall = 'api/Call';
  private apiTask = 'api/Task';
  private apiEmail = 'api/Email';
  private apiDealUrl = 'api/Deal';
  private apiNoteUrl = 'api/Note';
  private apiMeeting = 'api/Meeting';
  private apiAccountUrl = 'api/Account';
  private apiCantactUrl = 'api/Contact';
  private apiCampaignUrl = 'api/Campaign';
  private apiAttachmentUrl = 'api/Attachment';
  private apiDealStatusDetailUrl = 'api/DealStatusDetail';

  constructor(private baseDataService: BaseDataService) {}

  getDealById(id: string): Observable<DealView> {
    return this.baseDataService.makeGetCall(`${this.apiDealUrl}/${id}`);
  }

  deleteDeal(dealId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiDealUrl}/${dealId}`);
  }

  dealClone(id: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiDealUrl}/dealClone?dealId=${id}`
    );
  }

  // Note API endpoints
  createNote(note: DealNote): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiNoteUrl}`, note);
  }

  // getAllNotes(id: string): Observable<DealNote[]> {
  //   return this.baseDataService.makeGetCall(
  //     `${this.apiNoteUrl}/getAllNotesByReferenceId?refereceId=${id}`
  //   );
  // }

  deleteNote(noteId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiNoteUrl}/${noteId}`);
  }

  editNote(note: DealNote): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiNoteUrl}`, note);
  }

  // Attachment API endpoints
  createAttachment(documentUpload: DocumentUpload): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiAttachmentUrl}`,
      documentUpload
    );
  }

  getAllAttachment(id: string): Observable<DocumentUpload[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiAttachmentUrl}/getAttachmentsByReferenceId?refereceId=${id}`
    );
  }

  deleteAttachment(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiAttachmentUrl}/${id}`
    );
  }

  editAttachment(documentUpload: DocumentUpload): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiAttachmentUrl}`,
      documentUpload
    );
  }

  //Email API endpoints
  createEmail(email: ComposeEmail): Observable<ComposeEmail> {
    return this.baseDataService.makePostCall(`${this.apiEmail}`, email);
  }

  getAllEmails(
    emailStatus: string,
    referenceId: string
  ): Observable<ComposeEmail[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiEmail}/getAllEmailsByReferenceId?emailStatus=${emailStatus}&referenceId=${referenceId}`
    );
  }

  getAllEmailsByReferenceId(reqObj: any): Observable<ComposeEmail[]> {
    return this.baseDataService.makePostCall(
      `${this.apiEmail}/getAllEmailsByReferenceId`,
      reqObj
    );
  }

  deleteDraftMail(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiEmail}/${id}`);
  }

  getEmailById(id: string): Observable<ComposeEmail> {
    return this.baseDataService.makeGetCall(`${this.apiEmail}?emailId=${id}`);
  }

  updateEmail(email: ComposeEmail): Observable<ComposeEmail> {
    return this.baseDataService.makePutCall(`${this.apiEmail}`, email);
  }

  getDraftScheduleEmailsByReferenceId(
    emailStatus: string,
    referenceId: string
  ): Observable<ComposeEmail[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiEmail}/getDraftScheduleEmailsByReferenceId?emailStatus=${emailStatus}&referenceId=${referenceId}`
    );
  }

  cancelScheduledEmail(id: string): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiEmail}/cancelScheduledEmail?emailId=${id}`,
      ''
    );
  }

  //Task API endpoints
  createTask(task: ActivityTask): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiTask}`, task);
  }

  getTaskList(filter: BaseFilterVm): Observable<OpenActivityTask[]> {
    return this.baseDataService.makePostCall(
      `${this.apiTask}/getAlltasks`,
      filter
    );
  }

  getTasksByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityTask[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getTasksByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  //call log API endpoints
  createCallLog(callLog: CallLog): Observable<CallLog> {
    return this.baseDataService.makePostCall(`${this.apiCall}`, callLog);
  }

  getAllCallLogs(filter: BaseFilterVm): Observable<CallLog[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCall}/getAllCalls`,
      filter
    );
  }

  getAllCallLogsByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityCall[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCall}/getCallsByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  //meeting API endpoints
  getAllMeetings(filter: BaseFilterVm): Observable<OpenActivityMeeting[]> {
    return this.baseDataService.makePostCall(
      `${this.apiMeeting}/getAllMeetings`,
      filter
    );
  }

  getAllMeetingsByReferenceId(
    id: string,
    module: string,
    status: string
  ): Observable<OpenActivityMeeting[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiMeeting}/getMeetingsByReferenceId?referenceId=${id}&module=${module}&status=${status}`
    );
  }

  createMeetings(
    openActivityMetting: OpenActivityMeeting
  ): Observable<OpenActivityMeeting> {
    return this.baseDataService.makePostCall(
      `${this.apiMeeting}`,
      openActivityMetting
    );
  }

  getAccountsByDealId(dealId: string): Observable<Account[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiAccountUrl}/getAccountsByDealId?dealId=${dealId}`
    );
  }

  getCampaignsByDealId(dealId: string): Observable<Campaign[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCampaignUrl}/getCampaignsByDealId?dealId=${dealId}`
    );
  }

  getContactsByDealId(dealId: string): Observable<Contact[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCantactUrl}/getContactsByDealId?dealId=${dealId}`
    );
  }

  //deal stage verify
  verifyDealStageDetail(dealStageVerifyDetail: DealStageVerifyDetail) {
    return this.baseDataService.makePutCall(
      `${this.apiDealUrl}/dealStageVerifyDetail`,
      dealStageVerifyDetail
    );
  }

  getDealStatusDetailsByDealId(id: string): Observable<DealStatusDetail[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiDealStatusDetailUrl}/getActiveDealStatusByDealId?dealId=${id}`
    );
  }

  // Opened Closed Activities Count
  getOpenedClosedActivitiesByReferenceId(
    id: string,
    module: string
  ): Observable<OpenedClosedActivitiesCount> {
    return this.baseDataService.makeGetCall(
      `${this.apiTask}/getOpenedClosedActivitiesByReferenceId?referenceId=${id}&module=${module}`
    );
  }
}
