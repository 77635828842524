<ng-template #template>
  <div class="row container">
    <div class="col-md-6 mt-2">
      <h5 class="modal-title pull-left">
        <b>{{ 'DEALS_MENU.ASSIGN_DEALS' | translate }}</b>
      </h5>
    </div>
    <div class="col-md-6 d-flex flex-row-reverse mt-4">
      <button
        *ngIf="!isAssigning"
        mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size mt-3"
        (click)="assignDeals()"
        [disabled]="dealsIdList.length == 0"
      >
        {{ 'DEALS_MENU.ASSIGN_DEALS' | translate
        }}<mat-icon>check_box</mat-icon>
      </button>
      <button
        mat-flat-button
        class="flat-info-btn mt-3"
        color="primary"
        *ngIf="isAssigning"
      >
        <mat-spinner diameter="20"></mat-spinner>
      </button>
      &nbsp;&nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn btn-text-size mt-3"
        (click)="handleCancel()"
      >
        {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
      &nbsp;&nbsp;&nbsp;
      <div [formGroup]="searchForm" class="mb-4">
        <mat-form-field class="example-full-width search-input">
          <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="Search"
            (input)="onSearchChange($event.target.value)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row">
      <div class="col-md-12 table-container">
        <table mat-table [dataSource]="dataSource" class="fleet-table">
          <ng-container matColumnDef="Checkbox">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (change)="onCheckboxChange($event, element.id)"
                class="example-margin"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="DealName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.DEAL_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.dealName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Amount">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.AMOUNT' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Stage">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.STAGE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stage }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ClosingDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.CLOSING_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                this.helpersService
                  .getDateFromDb(element.closingDate)
                  ?.format('DD/MM/YYYY')
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="AccountName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.ACCOUNT_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.accountName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ContactName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.CONTACT_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="DealOwner">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DEALS_MENU.DEAL_OWNER' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.dealOwner }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div></div>

        <mat-paginator
          class="customPaginator"
          [pageSizeOptions]="[5, 10]"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [length]="totalItems"
          showFirstLastButtons
          (page)="onPageChange($event)"
          aria-label="Select page of periodic elements"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</ng-template>
