import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { Contact } from '../../deals/deal-view/models/deal-view.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  ActivityCall,
  CallViewDetails,
  CancelCall,
  LogCall,
  LogCallGroupedList,
  LogCallPaginated,
  RescheduleCall,
  ScheduleCall,
} from '../models/calls.model';

@Injectable({
  providedIn: 'root',
})
export class CallsService {
  private apiCallUrl = 'api/Call';
  private contactCall = '/api/Contact/getAllContacts';

  constructor(private baseDataService: BaseDataService) {}

  getAllCalls(
    filter: BaseFilterVm
  ): Observable<LogCallPaginated | LogCallGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCallUrl}/getAllCalls`,
      filter
    );
  }

  createLogCall(logCall: LogCall): Observable<LogCall> {
    return this.baseDataService.makePostCall(`${this.apiCallUrl}`, logCall);
  }

  deleteCalls(callsId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiCallUrl}/${callsId}`);
  }

  getCalls(id: string): Observable<CallViewDetails> {
    return this.baseDataService.makeGetCall(`${this.apiCallUrl}/${id}`);
  }

  createContactLog(contactLog: Contact): Observable<Contact> {
    return this.baseDataService.makePostCall(`${this.contactCall}`, contactLog);
  }

  markAsCompleted(callId: string): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiCallUrl}/markAsCompleted?callId=${callId}`,
      ''
    );
  }

  editCallLog(logCall: LogCall): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiCallUrl}`, logCall);
  }

  cloneCall(callId: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCallUrl}/clone?callId=${callId}`
    );
  }

  createScheduleCall(scheduleCall: ScheduleCall): Observable<ScheduleCall> {
    return this.baseDataService.makePostCall(
      `${this.apiCallUrl}`,
      scheduleCall
    );
  }

  editScheduleCall(scheduleCall: ScheduleCall): Observable<ScheduleCall> {
    return this.baseDataService.makePutCall(`${this.apiCallUrl}`, scheduleCall);
  }

  createCall(call: ActivityCall): Observable<ActivityCall> {
    return this.baseDataService.makePostCall(`${this.apiCallUrl}`, call);
  }

  editCall(call: ActivityCall): Observable<ActivityCall> {
    return this.baseDataService.makePutCall(`${this.apiCallUrl}`, call);
  }

  rescheduleCall(rescheduleCall: RescheduleCall): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiCallUrl}/rescheduleCall`,
      rescheduleCall
    );
  }

  cancelCall(cancelCall: CancelCall): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiCallUrl}/cancelCall`,
      cancelCall
    );
  }

  bulkCallsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiCallUrl}/deleteRangeCallsAsync`,
      ids
    );
  }
}
