import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de','ar'];


  private languageChangedEvenvt = new BehaviorSubject(null);

  constructor(public translate: TranslateService) {
    let browserLang;
    translate.addLangs(this.languages);

    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang');
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|ar/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    this.setLanguageSubject(lang)
  }

  getTranString(word:string){
    return this.translate.get(word);
  }

  setLanguageSubject(lang: string) {
    return this.languageChangedEvenvt.next(lang);
  }
  getLanguageChangedSubject(): Observable<string> {
    return this.languageChangedEvenvt.asObservable();
  }

  
  
}
