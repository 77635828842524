import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { HelpersService } from '../../services/helpers.service';
import { REPEAT_END_TYPE } from '../../data/constants';
import { CommonSharedService } from '../../services/common-shared.service';
import { LookupsService } from '../../services/lookups.service';
import { LookUpElement, Repeat } from '../../models/common-data.model';

@Component({
  selector: 'app-common-repeat-modal',
  templateUrl: './common-repeat-modal.component.html',
  styleUrls: ['./common-repeat-modal.component.scss'],
})
export class CommonRepeatModalComponent {
  @ViewChild('template', { static: true }) template: any;
  dataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  repeatForm: FormGroup;
  isLoading: boolean = false;
  repeatTypeSearch: string;
  repeatTypeList: LookUpElement[];
  repeatData: Repeat;
  frequencySearch: string;
  frequencyList: LookUpElement[];
  selectedRepeatType: string;
  selectedFrequencyType: string;
  @Input() isRepeatExist: boolean = false;
  @Input() dueDate: Date = null;
  daysOfWeek: any = [
    {
      index: 0,
      dayName: 'Sunday',
      shortName: 'S',
      selected: true,
    },
    {
      index: 1,
      dayName: 'Monday',
      shortName: 'M',
      selected: false,
    },
    {
      index: 2,
      dayName: 'Tuesday',
      shortName: 'T',
      selected: false,
    },
    {
      index: 3,
      dayName: 'Wednesday',
      shortName: 'W',
      selected: false,
    },
    {
      index: 4,
      dayName: 'Thursday',
      shortName: 'T',
      selected: false,
    },
    {
      index: 5,
      dayName: 'Friday',
      shortName: 'F',
      selected: false,
    },
    {
      index: 6,
      dayName: 'Saturday',
      shortName: 'S',
      selected: false,
    },
  ];

  daysOfMonth = new Array(31).fill(1).map((value, index) => value + index);

  weeksOfMonth: any = [
    {
      id: 1,
      name: 'First',
    },
    {
      id: 2,
      name: 'Second',
    },
    {
      id: 3,
      name: 'Third',
    },
    {
      id: 4,
      name: 'Fourth',
    },
    {
      id: 5,
      name: 'Last',
    },
  ];

  monthsOfYear = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private commonSharedService: CommonSharedService,
    private lookupsService: LookupsService
  ) {
    this.dataSubject = commonSharedService
      .getSubjectOpenRepeatModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((id: string) => {
        if (id) {
          this.showModal();
        }
      });

    this.commonSharedService
      .getSubjectRepeatData()
      .pipe(takeUntil(this.destroy))
      .subscribe((repeatData: Repeat) => {
        if (repeatData) {
          this.repeatData = repeatData;
          this.mapData();
        }
      });
  }

  mapData() {
    this.repeatForm = this.fb.group({
      RepeatType: [this.repeatData?.repeatTypeId],
      EndType: [this.repeatData?.ends],
      RepeatTimes: [
        this.repeatData?.ends == 'AFTER' ? this.repeatData?.repeatTimes : null,
      ],
      OnDate: [this.repeatData?.ends == 'ON' ? this.repeatData?.onDate : null],
      Frequency: [this.repeatData?.frequencyId],
      DailyFrequencyCount: [
        this.repeatData?.dailyConfiguration
          ? this.repeatData?.dailyConfiguration?.frequency
          : null,
      ],
      WeeklyFrequencyCount: [
        this.repeatData?.weeklyConfiguration
          ? this.repeatData?.weeklyConfiguration?.weekFrequency
          : null,
      ],
      MonthlyFrequencyCount: [
        this.repeatData?.monthlyConfiguration
          ? this.repeatData?.monthlyConfiguration?.monthFrequency
          : null,
      ],

      CustomMonthlyConfiguration: [
        this.repeatData?.monthlyConfiguration
          ? this.repeatData?.monthlyConfiguration?.isOnDay
            ? 'CustomMonthlyFirstSelection'
            : 'CustomMonthlySecondSelection'
          : null,
      ],
      CustomMonthly_DayOfTheMonth: [
        this.repeatData?.monthlyConfiguration &&
        this.repeatData?.monthlyConfiguration?.isOnDay
          ? this.repeatData?.monthlyConfiguration?.dayOfTheMonth
          : null,
      ],
      CustomMonthly_SecondWeekNo: [
        this.repeatData?.monthlyConfiguration &&
        !this.repeatData?.monthlyConfiguration?.isOnDay
          ? this.repeatData?.monthlyConfiguration?.weekNo
          : null,
      ],
      CustomMonthly_SecondDayofTheMonth: [
        this.repeatData?.monthlyConfiguration &&
        !this.repeatData?.monthlyConfiguration?.isOnDay
          ? this.repeatData?.monthlyConfiguration?.dayOfWeek
          : null,
      ],

      CustomYearlyConfiguration: [
        this.repeatData?.yearlyConfiguration
          ? this.repeatData?.yearlyConfiguration?.isOnDay
            ? 'CustomYearlyFirstSelection'
            : 'CustomYearlySecondSelection'
          : null,
      ],
      CustomYearly_DayofMonth: [
        this.repeatData?.yearlyConfiguration &&
        this.repeatData?.yearlyConfiguration?.isOnDay
          ? this.repeatData?.yearlyConfiguration?.dayOfTheMonth
          : null,
      ],
      CustomYearly_MonthofYear: [
        this.repeatData?.yearlyConfiguration &&
        this.repeatData?.yearlyConfiguration?.isOnDay
          ? this.repeatData?.yearlyConfiguration?.monthOfTheYear
          : null,
      ],
      CustomYearly_SecondWeekNo: [
        this.repeatData?.yearlyConfiguration &&
        !this.repeatData?.yearlyConfiguration?.isOnDay
          ? this.repeatData?.yearlyConfiguration?.dayOfWeekNo
          : null,
      ],
      CustomYearly_SecondDayOfWeek: [
        this.repeatData?.yearlyConfiguration &&
        !this.repeatData?.yearlyConfiguration?.isOnDay
          ? this.repeatData?.yearlyConfiguration?.dayOfWeek
          : null,
      ],
      CustomYearly_SecondMonthOfYear: [
        this.repeatData?.yearlyConfiguration &&
        !this.repeatData?.yearlyConfiguration?.isOnDay
          ? this.repeatData?.yearlyConfiguration?.monthOfTheYear
          : null,
      ],
    });

    this.selectedRepeatType = this.repeatTypeList?.filter(
      (x) => x.id == this.repeatData?.repeatTypeId
    )[0]?.name;

    if (this.repeatData?.frequencyId) {
      this.selectedFrequencyType = this.frequencyList?.filter(
        (x) => x.id == this.repeatData?.frequencyId
      )[0]?.name;
    }

    if (this.repeatData?.weeklyConfiguration) {
      this.daysOfWeek.map((day) => {
        var index = this.repeatData?.weeklyConfiguration?.daysOfWeek?.indexOf(
          day.index
        );
        index !== -1 ? (day.selected = true) : (day.selected = false);

      });
    } else {
      this.daysOfWeek.map((day) => {
        day.index == 0 ? (day.selected = true) : (day.selected = false);
      });
    }
  }

  ngOnDestroy(): void {
    this.repeatForm?.reset();
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setSubjectOpenRepeatModal(null);
    this.selectedRepeatType = null;
    this.selectedFrequencyType = null;
  }

  ngOnInit(): void {
    this.createCancelCallForm();
    this.getRepeatTypeLookupList();
    this.getFrequencyLookupList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-m',
    });
  }

  getCurrentDate() {
    return new Date();
  }

  createCancelCallForm() {
    this.repeatForm = this.fb.group({
      RepeatType: [null],
      EndType: ['NEVER'],
      RepeatTimes: [null],
      OnDate: [null],
      Frequency: [null],
      DailyFrequencyCount: [null],
      WeeklyFrequencyCount: [null],
      MonthlyFrequencyCount: [null],
      CustomMonthlyConfiguration: [null],
      CustomMonthly_DayOfTheMonth: [null],
      CustomMonthly_SecondWeekNo: [null],
      CustomMonthly_SecondDayofTheMonth: [null],
      CustomYearlyConfiguration: [null],
      CustomYearly_DayofMonth: [null],
      CustomYearly_MonthofYear: [null],
      CustomYearly_SecondWeekNo: [null],
      CustomYearly_SecondDayOfWeek: [null],
      CustomYearly_SecondMonthOfYear: [null],
    });
  }

  cancelForm() {
    this.modalRef?.hide();
    this.repeatForm.reset();
    this.commonSharedService.setSubjectOpenRepeatModal(null);
    this.selectedRepeatType = null;
    this.selectedFrequencyType = null;
    this.commonSharedService.setSubjectCancelRepeatModal(true);

  }

  getRepeatTypeLookupList() {
    let isRepeatWithoutCustom: boolean = false;
    this.lookupsService
      .getReminderRepeaMasterLookup('RepeatType', isRepeatWithoutCustom)
      .subscribe((repeatTypeList: LookUpElement[]) => {
        this.repeatTypeList = repeatTypeList;
        this.repeatForm.get('RepeatType')?.setValue(this.repeatTypeList[0]?.id);
      });
  }

  getFrequencyLookupList() {
    let isRepeatWithoutCustom: boolean = true;
    this.lookupsService
      .getReminderRepeaMasterLookup('RepeatType', isRepeatWithoutCustom)
      .subscribe((frequencyList: LookUpElement[]) => {
        this.frequencyList = frequencyList;
      });
  }

  setRepeatData(formData: any, isApplyAllUpcoming?: boolean) {
    this.repeatData = {
      repeatTypeId: formData.RepeatType,
      ends: formData.EndType,
      repeatTimes: formData.RepeatTimes,
      onDate: formData.OnDate
        ? this.helpersService.getDateWithZeroTime(formData.OnDate)
        : null,
      frequencyId: formData.Frequency,
      dailyConfiguration:
        this.selectedFrequencyType == 'Daily'
          ? {
              frequency: formData.DailyFrequencyCount,
            }
          : null,
      weeklyConfiguration:
        this.selectedFrequencyType == 'Weekly'
          ? {
              weekFrequency: formData.WeeklyFrequencyCount,
              daysOfWeek: this.daysOfWeek
                .filter((x) => x.selected)
                .map((x) => x.index),
            }
          : null,
      yearlyConfiguration:
        this.selectedFrequencyType == 'Yearly'
          ? {
              isOnDay:
                formData.CustomYearlyConfiguration ==
                'CustomYearlyFirstSelection'
                  ? true
                  : false,
              dayOfTheMonth: formData.CustomYearly_DayofMonth,
              dayOfWeekNo: formData.CustomYearly_SecondWeekNo,
              dayOfWeek: formData.CustomYearly_SecondDayOfWeek,
              monthOfTheYear:
                formData.CustomYearlyConfiguration ==
                'CustomYearlyFirstSelection'
                  ? formData.CustomYearly_MonthofYear
                  : formData.CustomYearly_SecondMonthOfYear,
            }
          : null,
      monthlyConfiguration:
        this.selectedFrequencyType == 'Monthly'
          ? {
              monthFrequency: formData.MonthlyFrequencyCount,
              isOnDay:
                formData.CustomMonthlyConfiguration ==
                'CustomMonthlyFirstSelection'
                  ? true
                  : false,
              dayOfTheMonth: formData.CustomMonthly_DayOfTheMonth,
              weekNo: formData.CustomMonthly_SecondWeekNo,
              dayOfWeek: formData.CustomMonthly_SecondDayofTheMonth,
            }
          : null,
    };
    this.commonSharedService.setSubjectRepeatData(this.repeatData);
    if (isApplyAllUpcoming) {
      this.commonSharedService.setSubjectIsApplyAllUpcoming(true);
    }
    this.cancelForm();
  }

  setRepeatType(event: any) {
    this.selectedFrequencyType = '';
    this.repeatForm.get('Frequency').setValue(null);
    this.repeatForm.get('Frequency').clearValidators();
    this.repeatForm.get('Frequency').updateValueAndValidity();

    this.selectedRepeatType = this.repeatTypeList.filter(
      (x) => x.id == event.value
    )[0]?.name;

    this.clearDataAfterFrequencyChanged();
    this.updateValidation();
  }

  setFrequencyType(event: any) {
    // this.repeatForm.get('CustomMonthlyConfiguration').setValue(null);
    // this.repeatForm.get('CustomYearlyConfiguration').setValue(null);
    this.clearDataAfterFrequencyChanged();

    this.selectedFrequencyType = this.frequencyList.filter(
      (x) => x.id == event.value
    )[0]?.name;

    if (this.selectedFrequencyType == 'Yearly') {
      this.repeatForm
        .get('CustomYearlyConfiguration')
        .setValue('CustomYearlyFirstSelection');
    }

    if (this.selectedFrequencyType == 'Monthly') {
      this.repeatForm
        .get('CustomMonthlyConfiguration')
        .setValue('CustomMonthlyFirstSelection');
    }

    this.updateValidation();
  }

  OnClickWeekDay(day: any) {
    this.daysOfWeek.forEach((element) => {
      if (element.index == day.index) {
        element.selected = !element.selected;
      }
    });
  }

  clearDataAfterFrequencyChanged() {
    this.repeatForm.get('DailyFrequencyCount').setValue(null);
    this.repeatForm.get('WeeklyFrequencyCount').setValue(null);
    this.repeatForm.get('MonthlyFrequencyCount').setValue(null);
    this.repeatForm.get('CustomMonthlyConfiguration').setValue(null);
    this.repeatForm.get('CustomMonthly_DayOfTheMonth').setValue(null);
    this.repeatForm.get('CustomMonthly_SecondWeekNo').setValue(null);
    this.repeatForm.get('CustomMonthly_SecondDayofTheMonth').setValue(null);
    this.repeatForm.get('CustomYearlyConfiguration').setValue(null);
    this.repeatForm.get('CustomYearly_DayofMonth').setValue(null);
    this.repeatForm.get('CustomYearly_MonthofYear').setValue(null);
    this.repeatForm.get('CustomYearly_SecondWeekNo').setValue(null);
    this.repeatForm.get('CustomYearly_SecondDayOfWeek').setValue(null);
    this.repeatForm.get('CustomYearly_SecondMonthOfYear').setValue(null);
  }

  updateValidation() {
    this.repeatForm.get('DailyFrequencyCount').clearValidators();
    this.repeatForm.get('WeeklyFrequencyCount').clearValidators();
    this.repeatForm.get('MonthlyFrequencyCount').clearValidators();
    this.repeatForm.get('CustomMonthly_DayOfTheMonth').clearValidators();
    this.repeatForm.get('CustomMonthly_SecondWeekNo').clearValidators();
    this.repeatForm.get('CustomMonthly_SecondDayofTheMonth').clearValidators();
    this.repeatForm.get('CustomYearly_DayofMonth').clearValidators();
    this.repeatForm.get('CustomYearly_MonthofYear').clearValidators();
    this.repeatForm.get('CustomYearly_SecondWeekNo').clearValidators();
    this.repeatForm.get('CustomYearly_SecondDayOfWeek').clearValidators();
    this.repeatForm.get('CustomYearly_SecondMonthOfYear').clearValidators();

    this.repeatForm.get('DailyFrequencyCount').updateValueAndValidity();
    this.repeatForm.get('WeeklyFrequencyCount').updateValueAndValidity();
    this.repeatForm.get('MonthlyFrequencyCount').updateValueAndValidity();
    this.repeatForm.get('CustomMonthly_DayOfTheMonth').updateValueAndValidity();
    this.repeatForm.get('CustomMonthly_SecondWeekNo').updateValueAndValidity();
    this.repeatForm
      .get('CustomMonthly_SecondDayofTheMonth')
      .updateValueAndValidity();
    this.repeatForm.get('CustomYearly_DayofMonth').updateValueAndValidity();
    this.repeatForm.get('CustomYearly_MonthofYear').updateValueAndValidity();
    this.repeatForm.get('CustomYearly_SecondWeekNo').updateValueAndValidity();
    this.repeatForm
      .get('CustomYearly_SecondDayOfWeek')
      .updateValueAndValidity();
    this.repeatForm
      .get('CustomYearly_SecondMonthOfYear')
      .updateValueAndValidity();
  }
}
