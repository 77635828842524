<ng-template #template>
  <div class="rest-icon">
    <a
      style="cursor: pointer"
      (click)="resetFilter((isClearFromMainFilterMenu = false))"
    >
      <span class="text-end"
        ><i class="material-icons-outlined filter-btn-icon icon-font-size"
          >restart_alt</i
        ></span
      ></a
    >
  </div>
  <h6 class="modal-title pull-left filter-modal-title">Column Selections</h6>
  <div class="modal-body filter-menu-columns-rtl p-2">
    <div class="col-12 mt-3 filter-container">
      <form [formGroup]="filterForm" class="filter-form">
        <mat-form-field
          class="search-input mt-2 full-width-field"
          appearance=""
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            (input)="searchChange($event)"
            [(ngModel)]="columnsSearch"
            [ngModelOptions]="{ standalone: true }"
          />
          <mat-icon matSuffix class="face-icon">search</mat-icon>
        </mat-form-field>
        <mat-list
          role="list"
          class="mat-list"
          (cdkDropListDropped)="drop($event)"
          cdkDropList
        >
          <mat-list-item
            role="listitem"
            class="mat-list-item"
            *ngFor="let item of coloumnsData"
            formArrayName="columns"
            cdkDrag
          >
            <mat-icon class="drag-icon">drag_indicator</mat-icon>
            <mat-slide-toggle
              class="example-margin"
              [value]="item.data"
              id="{{ item.data }}"
              (change)="onCheckboxChange($event)"
              [checked]="
                this.filterForm?.get('columns')?.value?.includes(item.data)
              "
              [disabled]="
                filterdColumnData.length == 1 &&
                this.filterForm?.get('columns')?.value?.includes(item.data)
              "
            >
              {{ item?.translate | translate }}
            </mat-slide-toggle>
          </mat-list-item>
        </mat-list>
      </form>
    </div>
  </div>
  <hr />
  <div class="filter-close-div filter-menu-columns-rtl">
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="modalRef.hide()"
    >
      Close</button
    >&nbsp;&nbsp;
  </div>
</ng-template>
