<ng-template #template>
  <div class="rest-icon filter-menu-sortBy-rtl">
    <a style="cursor: pointer" (click)="resetFilter()">
      <span class="text-end"
        ><i class="material-icons-outlined filter-btn-icon icon-font-size"
          >restart_alt</i
        ></span
      ></a
    >
  </div>
  <h6 class="modal-title pull-left filter-modal-title">Sort By</h6>
  <!-- <div class="modal-header">
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
       </button>
    </div> -->
  <div class="modal-body filter-menu-sortBy-rtl p-2">
    <div class="col-12 filter-container">
      <form [formGroup]="filterForm" class="col-12 filter-form">
        <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
          formControlName="sortType">
          <mat-list-item role="listitem">
            <mat-radio-button [value]="false" [checked]="true">Ascending <i class="material-icons-outlined filter-btn-icon rotate-180 text-end ml-5 sort-icn-asc">filter_list</i>
            </mat-radio-button>
          </mat-list-item>
          <mat-list-item role="listitem">
            <mat-radio-button [value]="true">Descending <i class="material-icons-outlined filter-btn-icon text-end ml-5 sort-icn-dsc">filter_list</i>
            </mat-radio-button>
          </mat-list-item>
        </mat-radio-group> -->
        <hr />
        <div class="row">
          <div class="col-md-7 offset-md-1">
            <mat-form-field
              class="search-input mt-2 example-full-width"
              appearance=""
            >
              <mat-label>Search</mat-label>
              <input
                matInput
                (input)="searchChange($event)"
                [(ngModel)]="sortingSearchText"
                [ngModelOptions]="{ standalone: true }"
              />
              <mat-icon matSuffix class="face-icon">search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="sortBy">
          <mat-radio-button class="example-radio-button" *ngFor="let item of coloumnsData" [value]="item.data">
            {{item?.translate | translate}}
          </mat-radio-button>
        </mat-radio-group> -->
        <div
          formArrayName="SortByArr"
          class=""
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="row p-3 sort-form"
            *ngFor="let item of getSortArrayValues?.controls; let i = index"
            cdkDrag
          >
            <div class="col-md-9">
              <div class="row" [formGroupName]="i">
                <div class="col-md-1">
                  <mat-icon class="drag-icon">drag_indicator</mat-icon>
                </div>
                <div class="col-md-4">
                  <div>
                    <mat-slide-toggle
                      class="example-margin"
                      formControlName="IsActive"
                      [value]="
                        getSortArrayValues?.controls[i].get('IsActive').value
                      "
                    >
                      <label for="">
                        {{
                          getSortArrayValues?.controls[i].get('Translate').value
                            | translate
                        }}</label
                      >
                    </mat-slide-toggle>
                  </div>
                </div>
                <div
                  class="col-md-5 text-center"
                  *ngIf="getSortArrayValues?.controls[i].get('IsActive').value"
                >
                  <!-- <mat-radio-group formControlName="SortType">
                    <mat-radio-button value="ASC">ASC</mat-radio-button>
                    <mat-radio-button value="DSC">DSC</mat-radio-button>

                  </mat-radio-group> -->

                  <mat-button-toggle-group
                    formControlName="SortType"
                    class="small-toggle"
                  >
                    <mat-button-toggle value="ASC"
                      ><i class="material-icons-outlined rotate-180"
                        >filter_list</i
                      >
                      Ascending
                    </mat-button-toggle>
                    <mat-button-toggle value="DSC"
                      ><i class="material-icons-outlined">filter_list</i>
                      Descending
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr />
  <div class="filter-close-div filter-menu-sortBy-rtl">
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="applyFilter()"
    >
      Apply Filter<mat-icon>add</mat-icon></button
    >&nbsp;&nbsp;
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="modalRef.hide()"
    >
      Close</button
    >&nbsp;&nbsp;
  </div>
</ng-template>
