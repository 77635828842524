import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import {
  ApprovalStatusLookUpElement,
  LookUpElement,
} from '../../models/common-data.model';
import { HelpersService } from '../../services/helpers.service';
import { LookupsService } from '../../services/lookups.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivityCall } from 'src/app/home/calls/models/calls.model';

// import { ActivityCall } from '../../../home/calls/models/calls.model';
import { CallsService } from 'src/app/home/calls/services/calls.service';
import { CommonSharedService } from '../../services/common-shared.service';
import { LeadsService } from 'src/app/home/leads/services/leads.service';
import { LeadReq } from 'src/app/home/leads/models/leads.model';
import { ContactViewService } from 'src/app/home/contacts/contact-view/services/contact-view.service';
import { ContactView } from 'src/app/home/contacts/contact-view/models/contact-view.model';
import { AccountViewService } from 'src/app/home/accounts/account-view/services/account-view.service';
import { AccountView } from 'src/app/home/accounts/account-view/models/account-view.model';
import { DealViewService } from 'src/app/home/deals/deal-view/services/deal-view.service';
import { DealView } from 'src/app/home/deals/deal-view/models/deal-view.model';
import { CampaignViewService } from 'src/app/home/campaigns/campaign-view/services/campaign-view.service';
import { CampaignView } from 'src/app/home/campaigns/campaign-view/models/campaign-view.model';
import { ApprovalStatus, CallStatus, CallTypes } from '../../data/constants';

@Component({
  selector: 'app-common-call-modal',
  templateUrl: './common-call-modal.component.html',
  styleUrls: ['./common-call-modal.component.scss'],
})
export class CommonCallModalComponent {
  router: any;
  callId: string;
  isEdit: boolean;
  userSearch: string;
  callForm: FormGroup;
  isSchedule: boolean;
  modalRef: BsModalRef;
  callReq: ActivityCall;
  selectedCallType: string;
  callDetails: ActivityCall;
  isLoading: boolean = false;
  isCallMissed: boolean = false;
  callAddDataSubject: Subscription;

  usersList: LookUpElement[] = [];
  callTypeList: LookUpElement[] = [];
  callStatusList: LookUpElement[] = [];
  callResultList: LookUpElement[] = [];
  callPurposeList: LookUpElement[] = [];
  reminderTypeList: LookUpElement[] = [];
  approvalStatusList: ApprovalStatusLookUpElement[] = [];

  @Input() module: string;
  @Input() callToId: string | null = null;
  @Input() relatedToId: string | null = null;
  @Input() callToStatusId: string | null = null;
  @Input() relatedToStatusId: string | null = null;

  leadId: string | null = null;
  contactId: string | null = null;
  accountId: string | null = null;
  dealId: string | null = null;
  campaignId: string | null = null;

  callTypes = CallTypes;
  callStatus = CallStatus;
  approvalStatus = ApprovalStatus;

  callToName: string = '';

  @ViewChild('template', { static: true }) template: any;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private callsService: CallsService,
    private modalService: BsModalService,
    private lookupsService: LookupsService,
    private helpersService: HelpersService,
    private translateService: TranslateService,
    private commonSharedService: CommonSharedService,
    private leadsService: LeadsService,
    private contactViewService: ContactViewService,
    private accountViewService: AccountViewService,
    private dealViewService: DealViewService,
    private campaignViewService: CampaignViewService
  ) {
    this.getQueryParams();

    this.callAddDataSubject = commonSharedService
      .getsubjectOpenMeetingCallModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((callObj: any) => {
        if (callObj) {
          if (callObj?.type == 'Open') {
            this.isEdit = false;
            this.isSchedule = callObj?.isSchedule;
          } else {
            this.isEdit = true;
            this.callId = callObj?.id;
            this.isSchedule = callObj?.isSchedule;
            this.getLogCallByCallId();
          }

          this.showModal();

          if (this.isSchedule && !this.isEdit) {
            this.callForm
              .get('CallTypeId')
              ?.setValue(
                this.callTypeList?.filter(
                  (x) => x.name == this.callTypes.OUTBOUND
                )[0]?.id
              );
            this.selectedCallType = this.callTypes.OUTBOUND;
            this.callForm
              .get('OutgoingCallStatusId')
              ?.setValue(
                this.callStatusList?.filter(
                  (x) => x.name == this.callStatus.SCHEDULED
                )[0]?.id
              );
          }

          if (!this.isSchedule && !this.isEdit) {
            this.callForm
              .get('CallTypeId')
              ?.setValue(
                this.callTypeList?.filter(
                  (x) => x.name == this.callTypes.OUTBOUND
                )[0]?.id
              );
            this.selectedCallType = this.callTypes.OUTBOUND;
            this.callForm
              .get('OutgoingCallStatusId')
              ?.setValue(
                this.callStatusList?.filter(
                  (x) => x.name == this.callStatus.COMPLETED
                )[0]?.id
              );
            this.callForm.get('OutgoingCallStatusId')?.disable();
          }

          if (!this.isEdit) {
            this.setDefaultSubject();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.callForm?.reset();
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setsubjectOpenMeetingCallModal(null);
    this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectDealOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectContactOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(null);
    this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
      null
    );
  }

  ngOnInit(): void {
    this.createAddCallForm();
    this.getLookupList();
  }

  private getQueryParams(): void {
    const paramKeys = [
      'leadId',
      'contactId',
      'accountId',
      'dealId',
      'campaignId',
    ];

    paramKeys.forEach((key) => {
      this[key] = this.route.snapshot.queryParamMap.get(key);
    });
  }

  getLookupList() {
    this.getCallStatusList();
    this.getCallTypeList();
    this.getCallPurposeList();
    this.getCallResultList();
    this.getUserInformationList();
    this.getReminderTypeLookupList();
    this.getApprovalStatusLookup();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  createAddCallForm() {
    this.callForm = this.fb.group({
      CallTypeId: [null],
      OutgoingCallStatusId: [null],
      ToDate: [null],
      ToTime: [null],
      CallOwnerId: [null],
      Subject: [''],
      CallPurposeId: [null],
      CallAgenda: [''],
      CallResultId: [null],
      Description: [''],
      Minutes: [0],
      IsReminder: [false],
      ReminderOn: [''],
      ReminderAt: [''],
      ReminderType: [null],
    });
  }

  getLogCallByCallId() {
    this.callsService
      .getCalls(this.callId)
      .pipe(takeUntil(this.destroy))
      .subscribe((logCall: any) => {
        this.callDetails = logCall;
        this.mapFormData();
      });
  }

  mapFormData() {
    this.callForm = this.fb.group({
      CallTypeId: [this.callDetails.callTypeId],
      OutgoingCallStatusId: [this.callDetails.outgoingCallStatusId],
      ToDate: [
        this.helpersService.getDateFromDbFormatted(this.callDetails.startTime),
      ],
      ToTime: [this.helpersService.getTimeFromDb(this.callDetails.startTime)],
      CallOwnerId: [this.callDetails.callOwnerId],
      Subject: [this.callDetails.subject],
      CallPurposeId: [this.callDetails.callPurposeId],
      CallAgenda: [this.callDetails.callAgenda],
      CallResultId: [this.callDetails.callResultId],
      Description: [this.callDetails.description],
      Minutes: [this.callDetails.duration],
      IsReminder: [this.callDetails.isReminder],
      ReminderOn: [
        this.callDetails?.reminder
          ? this.helpersService.getDateFromDbFormatted(
              this.callDetails?.reminder?.dateTime
            )
          : '',
      ],
      ReminderAt: [
        this.callDetails?.reminder
          ? this.helpersService.getTimeFromDb(
              this.callDetails?.reminder?.dateTime
            )
          : '',
      ],
      ReminderType: [this.callDetails?.reminder?.reminderAlertId],
    });

    this.selectedCallType = this.callTypeList?.filter(
      (x) => x.id == this.callDetails.callTypeId
    )[0]?.name;
  }

  createOrEditCall(fromData: any, statusName: string) {
    const approvalStatusId =
      this.approvalStatusList?.find((status) => status.name == statusName)
        ?.id ?? '';

    if (this.isEdit) {
      this.editCallLog(fromData, approvalStatusId);
    } else {
      this.createCall(fromData, approvalStatusId);
    }
  }

  createCall(fromData: any, approvalStatusId: string) {
    this.setCallReqBody(fromData, approvalStatusId);
    this.saveCallInDb();
  }

  setCallReqBody(formData: any, approvalStatusId: string) {
    var Date = moment(formData.ToDate).format('DD-MM-YYYY');
    var from = moment(
      Date + ' ' + formData.ToTime,
      'DD-MM-YYYY hh:mm A'
    ).toISOString();

    var reminderOnDate = moment(formData.ReminderOn).format('DD-MM-YYYY');
    var reminderDateTime = moment(
      reminderOnDate + ' ' + formData.ReminderAt,
      'DD-MM-YYYY hh:mm A'
    ).toDate();

    this.callReq = {
      subject: formData.Subject,
      callTypeId: formData.CallTypeId,
      startTime: from,
      callToStatusId: this.callToStatusId,
      callToId: this.callToId,
      relatedToStatusId: this.relatedToStatusId,
      relatedToId: this.relatedToId,
      duration: formData.Minutes ?? 0,
      outgoingCallStatusId: formData.OutgoingCallStatusId,
      callPurposeId: formData.CallPurposeId,
      callResultId: formData.CallResultId ?? null,
      callAgenda: formData.CallAgenda ?? '',
      description: formData.Description ?? '',
      approvalStatusId: approvalStatusId,
      callOwnerId: formData.CallOwnerId ?? null,
      isScheduled: this.isSchedule,
      isReminder: formData.IsReminder ?? false,
      reminder: formData.ReminderType
        ? {
            dateTime: this.helpersService.setDateForDb(reminderDateTime),
            reminderAlertId: formData.ReminderType,
          }
        : null,
    };
  }

  saveCallInDb() {
    this.isLoading = true;
    this.callsService.createCall(this.callReq).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res) {
          this.cancelForm();
          this.helpersService.showCreateSuccessToast();
          this.setOpenActivityTableChanged();
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  editCallLog(fromData: any, approvalStatusId: string) {
    this.setUpdateCallLogReqBody(fromData, approvalStatusId);
    this.saveUpdateCallInDb();
  }

  setUpdateCallLogReqBody(formData: any, approvalStatusId: string) {
    var Date = moment(formData.ToDate).format('DD-MM-YYYY');
    var from = moment(
      Date + ' ' + formData.ToTime,
      'DD-MM-YYYY hh:mm A'
    ).toISOString();

    var reminderOnDate = moment(formData.ReminderOn).format('DD-MM-YYYY');
    var reminderDateTime = moment(
      reminderOnDate + ' ' + formData.ReminderAt,
      'DD-MM-YYYY hh:mm A'
    ).toDate();

    this.callReq = {
      id: this.callId,
      subject: formData.Subject,
      callTypeId: formData.CallTypeId,
      startTime: from,
      callToStatusId: this.callDetails.callToStatusId,
      callToId: this.callToId,
      relatedToId: this.callDetails.relatedToId,
      relatedToStatusId: this.callDetails.relatedToStatusId,
      duration: formData.Minutes ?? 0,
      outgoingCallStatusId: formData.OutgoingCallStatusId,
      callPurposeId: formData.CallPurposeId,
      callResultId: formData.CallResultId,
      callAgenda: formData.CallAgenda ?? '',
      description: formData.Description ?? '',
      approvalStatusId: approvalStatusId,
      callOwnerId: formData.CallOwnerId,
      isScheduled: this.isSchedule,
      isReminder: formData.IsReminder ?? false,
      reminder: formData.ReminderType
        ? {
            dateTime: this.helpersService.setDateForDb(reminderDateTime),
            reminderAlertId: formData.ReminderType,
          }
        : null,
    };
  }

  saveUpdateCallInDb() {
    this.isLoading = true;
    this.callsService.editCall(this.callReq).subscribe(
      (res: any) => {
        if (res) {
          this.isLoading = false;
          this.cancelForm();
          this.helpersService.showUpdateSuccessToast();
          this.setOpenActivityTableChanged();
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getCallTypeList() {
    this.lookupsService
      .getCallMasterLookup('CallType')
      .subscribe((callTypeList: LookUpElement[]) => {
        this.callTypeList = callTypeList;
        if (this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('CallTypeId')
            ?.setValue(
              this.callTypeList?.filter(
                (x) => x.name == this.callTypes.OUTBOUND
              )[0]?.id
            );
          this.selectedCallType = this.callTypes.OUTBOUND;
        }

        if (!this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('CallTypeId')
            ?.setValue(
              this.callTypeList?.filter(
                (x) => x.name == this.callTypes.OUTBOUND
              )[0]?.id
            );
        }
      });
  }

  getCallPurposeList() {
    this.lookupsService
      .getCallMasterLookup('callPurpose')
      .subscribe((callPurposeList: LookUpElement[]) => {
        this.callPurposeList = callPurposeList;
      });
  }

  getCallResultList() {
    this.lookupsService
      .getCallMasterLookup('callResult')
      .subscribe((callResultList: LookUpElement[]) => {
        this.callResultList = callResultList;
      });
  }

  getCallStatusList() {
    this.lookupsService
      .getCallMasterLookup('CallStatus')
      .subscribe((callStatusList: LookUpElement[]) => {
        this.callStatusList = callStatusList;

        if (this.isSchedule && !this.isEdit) {
          this.selectedCallType = this.callTypes.OUTBOUND;
          this.callForm
            ?.get('OutgoingCallStatusId')
            ?.setValue(
              this.callStatusList?.filter(
                (x) => x.name == this.callStatus.SCHEDULED
              )[0]?.id
            );
        }
        if (!this.isSchedule && !this.isEdit) {
          this.callForm
            ?.get('OutgoingCallStatusId')
            ?.setValue(
              this.callStatusList?.filter(
                (x) => x.name == this.callStatus.COMPLETED
              )[0]?.id
            );
        }
      });
  }

  getUserInformationList() {
    this.lookupsService
      .getUserInformationLookUp()
      .pipe(takeUntil(this.destroy))
      .subscribe((usersList: LookUpElement[]) => {
        this.usersList = usersList;
      });
  }

  getReminderTypeLookupList() {
    this.lookupsService
      .getReminderRepeaMasterLookup('ReminderAlert')
      .subscribe((reminderTypeList: LookUpElement[]) => {
        this.reminderTypeList = reminderTypeList;
      });
  }

  getApprovalStatusLookup() {
    this.lookupsService
      .getApprovalStatusLookup()
      .pipe(takeUntil(this.destroy))
      .subscribe((approvalStatusList: ApprovalStatusLookUpElement[]) => {
        this.approvalStatusList = approvalStatusList;
      });
  }

  hancleSelectCallType(event: any) {
    this.callForm.get('OutgoingCallStatusId')?.setValue(null);
    this.callForm.get('OutgoingCallStatusId')?.enable();

    this.selectedCallType = this.callTypeList.filter(
      (x) => x.id == event.value
    )[0]?.name;
    if (this.selectedCallType == this.callTypes.OUTBOUND) {
      this.callForm
        .get('OutgoingCallStatusId')
        ?.setValue(
          this.callStatusList?.filter(
            (x) => x.name == this.callStatus.COMPLETED
          )[0]?.id
        );
      this.callForm.get('OutgoingCallStatusId')?.disable();
    }

    if (this.selectedCallType == this.callTypes.MISSED) {
      this.isCallMissed = true;
      this.callForm.get('Minutes')?.setValue(0);
    } else {
      this.isCallMissed = false;
    }
    this.setSubject();
  }

  setSubject() {
    if (!this.isEdit) {
      if (this.selectedCallType == this.callTypes.OUTBOUND) {
        this.callForm
          .get('Subject')
          ?.setValue(
            this.translateService.instant('COMMON.OUTGOING_CALL_TO') +
              ' ' +
              this.callToName
          );
      } else if (this.selectedCallType == this.callTypes.HIGHEST) {
        this.callForm
          .get('Subject')
          ?.setValue(
            this.translateService.instant('COMMON.INCOMING_CALL_FROM') +
              ' ' +
              this.callToName
          );
      } else if (this.selectedCallType == this.callTypes.MISSED) {
        this.callForm
          .get('Subject')
          ?.setValue(
            this.translateService.instant('COMMON.MISSED_CALL_FROM') +
              ' ' +
              this.callToName
          );
      }
    }
  }

  getCurrentDate() {
    return new Date();
  }

  cancelForm() {
    this.callForm.reset();
    this.modalRef?.hide();
    this.commonSharedService.setsubjectOpenMeetingCallModal(null);
  }

  backClick() {
    this.modalRef?.hide();
    this.callForm.reset();
  }

  setOpenActivityTableChanged() {
    if (this.module == 'Lead') {
      this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(true);
    } else if (this.module == 'Contact') {
      this.commonSharedService.setSubjectContactOpenActivityCallListChanged(
        true
      );
    } else if (this.module == 'Account') {
      this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(
        true
      );
    } else if (this.module == 'Deal') {
      this.commonSharedService.setSubjectDealOpenActivityCallListChanged(true);
    } else if (this.module == 'Campaign') {
      this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
        true
      );
    }
  }

  setDefaultSubject() {
    if (this.leadId) {
      this.getLeadData();
    } else if (this.contactId) {
      this.getContactData();
    } else if (this.accountId) {
      this.getAccountData();
    } else if (this.dealId) {
      this.getDealData();
    } else if (this.campaignId) {
      this.getCampaignData();
    }
  }

  setSubjectValue() {
    if (this.isSchedule) {
      this.callForm
        .get('Subject')
        ?.setValue(
          this.translateService.instant('COMMON.CALL_SCHEDULED_WITH') +
            ' ' +
            this.callToName
        );
    } else {
      this.callForm
        .get('Subject')
        ?.setValue(
          this.translateService.instant('COMMON.OUTGOING_CALL_TO') +
            ' ' +
            this.callToName
        );
    }
  }

  getLeadData() {
    this.leadsService
      .getLead(this.leadId)
      .pipe(takeUntil(this.destroy))
      .subscribe((lead: LeadReq) => {
        this.callToName = lead.firstName + ' ' + lead.lastName;

        this.setSubjectValue();
      });
  }

  getContactData() {
    this.contactViewService
      .getContactById(this.contactId)
      .pipe(takeUntil(this.destroy))
      .subscribe((contact: ContactView) => {
        this.callToName = contact.firstName + ' ' + contact.lastName;

        this.setSubjectValue();
      });
  }

  getAccountData() {
    this.accountViewService
      .getAccountById(this.accountId)
      .pipe(takeUntil(this.destroy))
      .subscribe((account: AccountView) => {
        this.callToName = account.accountName;

        this.setSubjectValue();
      });
  }

  getDealData() {
    this.dealViewService
      .getDealById(this.dealId)
      .pipe(takeUntil(this.destroy))
      .subscribe((deal: DealView) => {
        this.callToName = deal.dealName;

        this.setSubjectValue();
      });
  }

  getCampaignData() {
    this.campaignViewService
      .getCampaignById(this.campaignId)
      .pipe(takeUntil(this.destroy))
      .subscribe((campaign: CampaignView) => {
        this.callToName = campaign.campaignName;

        this.setSubjectValue();
      });
  }
}
