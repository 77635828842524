import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private helperService: HelpersService,
    private translateService: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;

      if (
        route.data['role'] &&
        Array.isArray(route.data['role']) &&
        !this.helperService.isCRMAdmin(userRole)
      ) {
        const exitRoles = route.data['role'].filter((x) =>
          userRole.includes(x)
        );

        if (exitRoles.length == 0) {
          this.router.navigate(['/authentication/signin']);
          Swal.fire({
            title: this.translateService.instant(
              'COMMON.YOU_DO_NOT_HAVE_ACCESS_TO_THIS_PAGE'
            ),
            icon: 'warning',
            confirmButtonColor: '#e0272a',
            confirmButtonText: this.translateService.instant('COMMON.OKAY'),
          });
          return false;
        }
      }

      return true;
    }

    this.router.navigate(['/authentication/signin']);
    return false;
  }
}
