<ng-template #template>
  <h5 class="modal-title pull-left">
    {{ 'COMMON.CHANGE_PASSWORD' | translate }}
  </h5>

  <div class="modal-body p-2">
    <form [formGroup]="passwordForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.CURRENT_PASSWORD' | translate }}</mat-label>
            <input
              [type]="hideCurrent ? 'password' : 'text'"
              formControlName="CurrentPassword"
              matInput
              required
            />
            <mat-icon matSuffix (click)="hideCurrent = !hideCurrent">{{
              hideCurrent ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <mat-error
              *ngIf="passwordForm.get('CurrentPassword').hasError('required')"
            >
              {{
                'SIGNIN_MENU.CURRENT_PASSWORD_IS_REQUIRED' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="passwordForm.get('CurrentPassword').hasError('pattern')"
            >
              {{ 'COMMON.THE_PASSWORD_FORMAT_IS_INCORRECT' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.NEW_PASSWORD' | translate }}</mat-label>
            <input
              [type]="hideNew ? 'password' : 'text'"
              formControlName="NewPassword"
              matInput
              required
              (input)="changePassword()"
            />
            <mat-icon matSuffix (click)="hideNew = !hideNew">{{
              hideNew ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <mat-error
              *ngIf="passwordForm.get('NewPassword').hasError('required')"
            >
              {{
                'SIGNIN_MENU.NEW_PASSWORD_IS_REQUIRED' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="passwordForm.get('NewPassword').hasError('pattern')"
            >
              {{ 'COMMON.THE_PASSWORD_FORMAT_IS_INCORRECT' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>
              {{ 'ADMINISTRATION.CONFIRM_PASSWORD' | translate }}</mat-label
            >
            <input
              [type]="hideConform ? 'password' : 'text'"
              formControlName="ConfirmPassword"
              matInput
              required
              disabled
            />
            <mat-icon matSuffix (click)="hideConform = !hideConform">{{
              hideConform ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <mat-error
              *ngIf="passwordForm.get('ConfirmPassword').hasError('required')"
            >
              {{
                'SIGNIN_MENU.CONFIRM_PASSWORD_IS_REQUIRED' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="
                passwordForm
                  ?.get('ConfirmPassword')
                  ?.hasError('passwordMismatch')
              "
            >
              {{
                'ADMINISTRATION.PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH'
                  | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="passwordForm.get('ConfirmPassword').hasError('pattern')"
            >
              {{ 'COMMON.THE_PASSWORD_FORMAT_IS_INCORRECT' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12 note-msg">
          <mat-hint
            ><b>{{ 'COMMON.NOTE' | translate }} :</b>
            {{ 'COMMON.PASSWORD_HINT_MSG' | translate }}</mat-hint
          >
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="cancelForm()"
    >
      {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
    </button>
    <button
      mat-flat-button
      class="flat-info-btn"
      color="primary"
      *ngIf="isLoading"
    >
      <mat-spinner diameter="20"></mat-spinner>
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="resetPassword(passwordForm.value)"
      [disabled]="
        !passwordForm.valid ||
        passwordForm.get('NewPassword').value !=
          passwordForm.get('ConfirmPassword').value
      "
      *ngIf="!isLoading"
    >
      {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon>
    </button>
  </div>
</ng-template>
