import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { DealGroupedList, DealPaginated } from '../models/deal-list.model';
import { KanbanStatus } from 'src/app/shared/models/common-data.model';

@Injectable({
  providedIn: 'root',
})
export class DealListService {
  private apiDealUrl = 'api/Deal';

  constructor(public baseDataService: BaseDataService) {}

  getAllDeals(
    baseFilterVm: BaseFilterVm
  ): Observable<DealPaginated | DealGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiDealUrl}/getAllDeals`,
      baseFilterVm
    );
  }

  deleteDeal(dealId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiDealUrl}/${dealId}`);
  }

  bulkDealsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiDealUrl}/deleteRangeDealsAsync`,
      ids
    );
  }

  dealExcelExport(filter: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiDealUrl}/dealExcelExport`,
      filter
    );
  }

  dealTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiDealUrl}/templateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  dealUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiDealUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }

  updateDealKanbanStatus(kanbanStatus: KanbanStatus): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiDealUrl}/updateDealKanbanStatus`, kanbanStatus);
  }
}
