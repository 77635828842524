import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountView, ContactAssignment } from '../models/account-view.model';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { ContactEdit } from 'src/app/home/contacts/contact-edit/models/contact-edit.model';

@Injectable({
  providedIn: 'root',
})
export class AccountViewService {
  private apiAccountUrl = 'api/Account';
  private apiContactUrl = 'api/Contact';

  constructor(private baseDataService: BaseDataService) {}

  getAccountById(id: string): Observable<AccountView> {
    return this.baseDataService.makeGetCall(`${this.apiAccountUrl}/${id}`);
  }

  accountClone(id: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiAccountUrl}/clone?accountId=${id}`
    );
  }

  deleteAccount(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiAccountUrl}/${id}`);
  }

  getContactsByAccountId(
    baseFilterVm: BaseFilterVm,
    accountId: string
  ): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiContactUrl}/getContactsByAccountId?accountId=${accountId}`,
      baseFilterVm
    );
  }

  removeAssignedContact(contactId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiContactUrl}/${contactId}`
    );
  }

  getContactById(id: string): Observable<ContactEdit> {
    return this.baseDataService.makeGetCall(`${this.apiContactUrl}/${id}`);
  }

  editContact(contactEdit: ContactEdit): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiContactUrl}`,
      contactEdit
    );
  }

  isContactEmailExists(id: string, email: string): Observable<any> {
    var params = id ? 'id=' + id + '&email=' + email : 'email=' + email;
    return this.baseDataService.makeGetCall(
      `${this.apiContactUrl}/isContactEmailExists?${params}`
    );
  }

  getNullAccountContacts(baseFilterVm: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiContactUrl}/getNullAccountContacts`,
      baseFilterVm
    );
  }

  assignMultipleContacts(
    contactAssignment: ContactAssignment
  ): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiAccountUrl}/assignMultipleContacts`,
      contactAssignment
    );
  }

  accountUnAssignContacts(contactId: string): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiAccountUrl}/accountUnAssignContacts?contactId=${contactId}`,
      ''
    );
  }
}
