<ng-template #template>
  <div class="row container">
      <div class="col-md-6">
          <h5 class="modal-title pull-left"><b>Choose Deal</b></h5>
      </div>
      <div class="col-md-6 d-flex flex-row-reverse mt-4">
          <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="createDeal()">
              New Deal<mat-icon>add</mat-icon>
          </button>
      </div>
  </div>
  <div class="modal-body">
      <app-loader *ngIf="isLoading"></app-loader>
      <div class="row mt-2">
          <div class="col-md-12 table-container">
              <table mat-table [dataSource]="dataSource" class="fleet-table">
              <mat-radio-group [(ngModel)]="selectedDealId" (change)="onRadioButtonChange($event)">
                  <ng-container matColumnDef="Select">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                          <mat-radio-button [value]="element.id"></mat-radio-button>
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="DealName">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.DEAL_NAME' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element.dealName }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Amount">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.AMOUNT' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Stage">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.STAGE' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element?.dealStage }}</td>
                  </ng-container>
                  <ng-container matColumnDef="ClosingDate">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.CLOSING_DATE' | translate }}</th>
                      <td mat-cell *matCellDef="let element">
                          {{helpersService.getDateFromDb(element.closingDate)?.format('DD/MM/YYYY hh:mm A')}}
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="AccountName">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.ACCOUNT_NAME' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element.accountName }} </td>
                  </ng-container>
                  <ng-container matColumnDef="ContactName">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.CONTACT_NAME' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
                  </ng-container>
                  <ng-container matColumnDef="DealOwner">
                      <th mat-header-cell *matHeaderCellDef>{{ 'DEALS_MENU.DEAL_OWNER' | translate }}</th>
                      <td mat-cell *matCellDef="let element">{{ element.dealOwner }}</td>
                  </ng-container>
                  <ng-container matColumnDef="ApprovalStatus">
                      <th mat-header-cell *matHeaderCellDef>{{ 'INQUIRY_MENU.APPROVAL_STATUS' | translate }}</th>
                      <td mat-cell *matCellDef="let element">
                          <span *ngIf="element?.approvalState === 'DRAFT'" class="badge bg-status-draft">Draft</span>
                          <span *ngIf="element?.approvalState === 'APPROVED'" class="badge bg-green">Approved</span>
                      </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </mat-radio-group>
              </table>
              <mat-paginator
              class="customPaginator"
              [pageSizeOptions]="[5,10]"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [length]="totalItems"
              (page)="onPageChange($event)"
              showFirstLastButtons
              aria-label="Select page of periodic elements"
              ></mat-paginator>
          </div>
      </div>
  </div>
  <div class="modal-footer pe-4">
      <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="handleCancel()">
          {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
      <button mat-flat-button color="primary" class="flat-info-btn" *ngIf="isLoading">
          <mat-spinner diameter="20"></mat-spinner>
      </button>&nbsp;&nbsp;
      <button mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        (click)="onClickChooseDeal()"
        [disabled]="!selectedDealId">
            {{ 'COMMON.SELECT' | translate }}<mat-icon>check_box</mat-icon>
      </button>
  </div>
</ng-template>

<app-quick-create-deal-modal></app-quick-create-deal-modal>
