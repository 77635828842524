import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { Observable } from 'rxjs';
import { NotificationList } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService {
  private apiInAppNotificationUrl = 'api/InAppNotificationHistory';
  constructor(private baseDataService: BaseDataService) {}

  getAllInAppNotifications(
    isUnreadOnly: boolean = false
  ): Observable<NotificationList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiInAppNotificationUrl}/getAllInAppNotificationHistorys?isUnreadOnly=${isUnreadOnly}`,
      ''
    );
  }

  markAllNotificationsAsRead(): Observable<boolean> {
    return this.baseDataService.makePutCall(
      `${this.apiInAppNotificationUrl}/markAllAsRead`,
      ''
    );
  }

  markNotificationAsReadById(id: string): Observable<boolean> {
    return this.baseDataService.makePutCall(
      `${this.apiInAppNotificationUrl}/markAsRead?notificationId=${id}`,
      ''
    );
  }

  getNotificationUnreadCount(): Observable<any> {
    return this.baseDataService.makeGetCall(
      `${this.apiInAppNotificationUrl}/getUnreadCount`
    );
  }
}
