<ng-template #template>
  <h5 class="modal-title modal-container quick-create-modal-rtl">
    {{ 'COMMON.QUICK_CREATE_DEAL' | translate }}
  </h5>
  <div class="container modal-container">
    <form [formGroup]="dealForm">
      <div class="row quick-create-modal-rtl">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.DEAL_OWNER' | translate }}</mat-label>
            <mat-select formControlName="DealOwnerId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="userSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                  ></ngx-mat-select-search
                >
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let user of usersList | filter : userSearch"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.AMOUNT' | translate }}</mat-label>
            <input
              type="number"
              matInput
              formControlName="Amount"
              required
              [min]="0"
              pattern="^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$"
              (keypress)="helpersService.preventE($event)"
            />
            <mat-error *ngIf="dealForm?.get('Amount').hasError('min')">
              {{ 'COMMON.AMOUNT_SHOULD_BE_GREATER_THAN_ZERO' | translate }}
            </mat-error>
            <mat-error *ngIf="dealForm?.get('Amount').hasError('pattern')">
              {{ 'COMMON.PLEASE_ADD_VALID_AMOUNT' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.DEAL_NAME' | translate }}</mat-label>
            <input type="text" matInput formControlName="DealName" required />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.CLOSING_DATE' | translate }}</mat-label>
            <input
              formControlName="ClosingDate"
              matInput
              [matDatepicker]="picker1"
              readonly
              required
              [min]="getCurrentDate()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.ACCOUNT_NAME' | translate }}</mat-label>
            <mat-select formControlName="AccountId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="accountSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="let account of accountList | filter : accountSearch"
                [value]="account.id"
                >{{ account.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'DEALS_MENU.STAGE' | translate }}</mat-label>
            <mat-select formControlName="DealStageId" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="dealStageSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let dealStage of dealStageList | filter : dealStageSearch
                "
                [value]="dealStage.id"
                >{{ dealStage.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.EMAIL' | translate }}</mat-label>
            <input type="text" matInput formControlName="Email" required />
            <mat-error *ngIf="dealForm.get('Email').hasError('pattern')">
              {{ 'COMMON.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  &nbsp;&nbsp;&nbsp;
  <div class="row quick-create-modal-rtl ms-5">
    <div class="col-md-12 d-flex flex-row-reverse">
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn"
        *ngIf="isLoading"
      >
        <mat-spinner diameter="20"></mat-spinner></button
      >&nbsp;&nbsp;&nbsp;
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        [disabled]="!dealForm.valid"
        (click)="createDeal(dealForm.value)"
        *ngIf="!isLoading"
      >
        {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon></button
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn btn-text-size"
        (click)="cancelForm()"
      >
        {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
