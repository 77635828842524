import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  CampaignPaginated,
  CampaignGroupedList,
} from '../models/campaign-list.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignListService {
  private apiCampaignUrl = 'api/Campaign';

  constructor(public baseDataService: BaseDataService) {}

  getAllCampaigns(
    baseFilterVm: BaseFilterVm
  ): Observable<CampaignPaginated | CampaignGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/getAllCampaigns`,
      baseFilterVm
    );
  }

  deleteCampaign(campaignId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiCampaignUrl}/${campaignId}`
    );
  }

  bulkCampaignsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiCampaignUrl}/deleteRangeCampaignsAsync`,
      ids
    );
  }
}
