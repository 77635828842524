<ng-template #template>
  <h5 class="modal-title pull-left">
    <b>{{ 'COMMON.REPEAT' | translate }}</b>
  </h5>
  <div class="modal-body">
    <form [formGroup]="repeatForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label>
            <mat-select
              formControlName="RepeatType"
              (selectionChange)="setRepeatType($event)"
              required
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="repeatTypeSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let repeatType of repeatTypeList | filter : repeatTypeSearch
                "
                [value]="repeatType?.id"
                >{{ repeatType?.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedRepeatType == 'Custom'">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.FREQUENCY' | translate }}</mat-label>
            <mat-select
              formControlName="Frequency"
              (selectionChange)="setFrequencyType($event)"
              required
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="frequencySearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let frequency of frequencyList | filter : frequencySearch
                "
                [value]="frequency?.id"
                >{{ frequency?.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedFrequencyType == 'Daily'">
        <div class="col-md-2 text-container">Every</div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width form-input-field">
            <input
              type="number"
              matInput
              formControlName="DailyFrequencyCount"
              [required]="selectedFrequencyType == 'Daily' ? true : false"
              max="999"
              min="1"
              (keypress)="helpersService.preventEAndDot($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-md-2 text-container">days</div>
      </div>

      <div class="row" *ngIf="selectedFrequencyType == 'Weekly'">
        <div class="row">
          <div class="col-md-2 text-container">Every</div>
          <div class="col-md-4">
            <mat-form-field class="example-full-width form-input-field">
              <input
                type="number"
                matInput
                formControlName="WeeklyFrequencyCount"
                [required]="selectedFrequencyType == 'Weekly' ? true : false"
                max="999"
                min="1"
                (keypress)="helpersService.preventEAndDot($event)"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2 text-container">weeks</div>
        </div>
        <div class="row">
          <div class="col-md-12 text-container">Select Days :</div>
          <div class="d-flex justify-content-center">
            <div
              *ngFor="let day of daysOfWeek"
              (click)="OnClickWeekDay(day)"
              [ngClass]="
                day?.selected ? 'selected-icon week-icons' : 'week-icons'
              "
            >
              <span>{{ day?.shortName }}</span>
            </div>
          </div>
          <!-- <div class="col-md-12 text-container">Selected Days :</div>
          <div class="d-flex">
          <div
            *ngFor="let day of daysOfWeek"
          >
            <span class="text-container" *ngIf="day.selected">{{ day.dayName }} &nbsp;</span>
          </div>
        </div> -->
        </div>
      </div>

      <div class="row" *ngIf="selectedFrequencyType == 'Monthly'">
        <div class="row">
          <div class="col-md-2 text-container">Every</div>
          <div class="col-md-4">
            <mat-form-field class="example-full-width form-input-field">
              <input
                type="number"
                matInput
                formControlName="MonthlyFrequencyCount"
                [required]="selectedFrequencyType == 'Monthly' ? true : false"
                max="999"
                min="1"
                (keypress)="helpersService.preventEAndDot($event)"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2 text-container">months</div>
        </div>
        <div class="row">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            formControlName="CustomMonthlyConfiguration"
          >
            <mat-radio-button
              class="example-radio-button"
              value="CustomMonthlyFirstSelection"
            >
              <div class="row">
                <div class="col-md-3 text-container">On day</div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomMonthly_DayOfTheMonth"
                      [required]="
                        selectedFrequencyType == 'Monthly' &&
                        repeatForm.get('CustomMonthlyConfiguration').value ==
                          'CustomMonthlyFirstSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let day of daysOfMonth"
                        [value]="day"
                        >{{ day }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4 text-container">of the month(s)</div>
              </div>
            </mat-radio-button>
            <mat-radio-button
              class="example-radio-button"
              value="CustomMonthlySecondSelection"
            >
              <div class="row">
                <div class="col-md-1 text-container">On</div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomMonthly_SecondWeekNo"
                      [required]="
                        selectedFrequencyType == 'Monthly' &&
                        repeatForm.get('CustomMonthlyConfiguration').value ==
                          'CustomMonthlySecondSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let week of weeksOfMonth"
                        [value]="week.id"
                        >{{ week.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomMonthly_SecondDayofTheMonth"
                      [required]="
                        selectedFrequencyType == 'Monthly' &&
                        repeatForm.get('CustomMonthlyConfiguration').value ==
                          'CustomMonthlySecondSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let day of daysOfWeek"
                        [value]="day.index"
                        >{{ day.dayName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 text-container">of the month(s)</div>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row" *ngIf="selectedFrequencyType == 'Yearly'">
        <div class="row">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            formControlName="CustomYearlyConfiguration"
          >
            <mat-radio-button
              class="example-radio-button"
              value="CustomYearlyFirstSelection"
            >
              <div class="row">
                <div class="col-md-2 text-container">On</div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomYearly_DayofMonth"
                      [required]="
                        selectedFrequencyType == 'Yearly' &&
                        repeatForm.get('CustomYearlyConfiguration').value ==
                          'CustomYearlyFirstSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let day of daysOfMonth"
                        [value]="day"
                        >{{ day }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-1 text-container">of</div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomYearly_MonthofYear"
                      [required]="
                        selectedFrequencyType == 'Yearly' &&
                        repeatForm.get('CustomYearlyConfiguration').value ==
                          'CustomYearlyFirstSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let month of monthsOfYear"
                        [value]="month.id"
                        >{{ month.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-radio-button>
            <mat-radio-button
              class="example-radio-button custom-yearly-second-selction"
              value="CustomYearlySecondSelection"
            >
              <div class="row">
                <div class="col-md-2 text-container">On</div>
                <div class="col-md-5">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomYearly_SecondWeekNo"
                      [required]="
                        selectedFrequencyType == 'Yearly' &&
                        repeatForm.get('CustomYearlyConfiguration').value ==
                          'CustomYearlySecondSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let week of weeksOfMonth"
                        [value]="week.id"
                        >{{ week.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomYearly_SecondDayOfWeek"
                      [required]="
                        selectedFrequencyType == 'Yearly' &&
                        repeatForm.get('CustomYearlyConfiguration').value ==
                          'CustomYearlySecondSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let day of daysOfWeek"
                        [value]="day.index"
                        >{{ day.dayName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-2 text-container">of</div>
                <div class="col-md-5">
                  <mat-form-field class="example-full-width form-input-field">
                    <!-- <mat-label>{{ 'COMMON.REPEAT_TYPE' | translate }}</mat-label> -->
                    <mat-select
                      formControlName="CustomYearly_SecondMonthOfYear"
                      [required]="
                        selectedFrequencyType == 'Yearly' &&
                        repeatForm.get('CustomYearlyConfiguration').value ==
                          'CustomYearlySecondSelection'
                          ? true
                          : false
                      "
                    >
                      <mat-option
                        class="form-select-option"
                        *ngFor="let month of monthsOfYear"
                        [value]="month.id"
                        >{{ month.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <mat-label class="text-label">{{
            'COMMON.ENDS' | translate
          }}</mat-label>
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            formControlName="EndType"
          >
            <mat-radio-button class="example-radio-button" value="NEVER"
              >{{ 'COMMON.NEVER' | translate }}
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" value="AFTER">
              <div class="row">
                <div class="col-md-2 text-container">
                  {{ 'COMMON.AFTER' | translate }}
                </div>
                <div class="col-md-5">
                  <mat-form-field class="example-full-width form-input-field">
                    <input
                      type="number"
                      matInput
                      formControlName="RepeatTimes"
                      [required]="repeatForm?.get('EndType')?.value == 'AFTER'"
                      (keypress)="helpersService.preventEAndDot($event)"
                      max="999"
                      min="1"
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-2 text-container">
                  {{ 'COMMON.TIMES' | translate }}
                </div>
              </div>
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" value="ON">
              <div class="row">
                <div class="col-md-2 text-container">
                  {{ 'COMMON.ON' | translate }}
                </div>
                <div class="col-md-10">
                  <mat-form-field class="example-full-width form-input-field">
                    <mat-label>{{ 'EMAIL_MENU.DATE' | translate }}</mat-label>
                    <input
                      formControlName="OnDate"
                      matInput
                      [matDatepicker]="picker1"
                      readonly
                      [required]="repeatForm?.get('EndType')?.value == 'ON'"
                      [min]="
                        repeatForm?.get('EndType')?.value == 'ON'
                          ? dueDate
                            ? helpersService.addOneDay(dueDate)
                            : null
                          : null
                      "
                    />
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <!-- <mat-error *ngIf="repeatForm.get('OnDate').hasError('min')">
                      Repeat end date should be greater than due date
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-md-12 mb-2 d-flex align text-align-right flex-row-reverse">
      <div class="example-button-row">
        <button
          mat-stroked-button
          color="primary"
          class="btn-border outline-info-btn btn-text-size"
          (click)="cancelForm()"
        >
          {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
        </button>
        <button
          mat-flat-button
          class="flat-info-btn"
          color="primary"
          *ngIf="isLoading"
        >
          <mat-spinner diameter="20"></mat-spinner>
        </button>
        <button
          mat-flat-button
          color="primary"
          class="flat-info-btn btn-text-size"
          (click)="setRepeatData(repeatForm.value, false)"
          [disabled]="
            !repeatForm.valid || repeatForm?.get('EndType').value == null
          "
          *ngIf="!isLoading && !isRepeatExist"
        >
          {{ 'COMMON.DONE' | translate }}<mat-icon>done</mat-icon>
        </button>
        <button
          mat-flat-button
          color="primary"
          class="flat-info-btn btn-text-size"
          (click)="setRepeatData(repeatForm.value, true)"
          [disabled]="
            !repeatForm.valid || repeatForm?.get('EndType').value == null
          "
          *ngIf="!isLoading && isRepeatExist"
        >
          {{ 'COMMON.APPLY_ALL_UPCOMING_TASKS' | translate
          }}<mat-icon>done</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
