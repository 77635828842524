import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ComposeEmail,
  OpenedClosedActivitiesCount,
} from '../models/deal-view.model';

@Injectable({
  providedIn: 'root',
})
export class DealViewSharedService {
  private subjectNumberOfMail = new BehaviorSubject(null);
  private subjectDealStageVerify = new BehaviorSubject(null);
  private subjectCurrentDealMail = new BehaviorSubject(null);
  private subjectOpenTaskAddModal = new BehaviorSubject(null);
  private subjectEmailTableChanged = new BehaviorSubject(null);
  private subjectDraftTableChanged = new BehaviorSubject(null);
  private subjectOpenScheduleModal = new BehaviorSubject(null);
  private subjectNumberOfDraftMail = new BehaviorSubject(null);
  private subjectOpenCallsAddModal = new BehaviorSubject(null);
  private subjectCallLogTableChange = new BehaviorSubject(null);
  private subjectOpenTaskTableChange = new BehaviorSubject(null);
  private subjectOpenMeetingAddModal = new BehaviorSubject(null);
  private subjectNumberOfScheduleMail = new BehaviorSubject(null);
  private subjectRemoveEmailDraftModal = new BehaviorSubject(null);
  private subjectScheduledTableChanged = new BehaviorSubject(null);
  private subjectNumberOfCanceledMails = new BehaviorSubject(null);
  private subjectOpenMeetingTableChange = new BehaviorSubject(null);
  private subjectCancelEmailTableChanged = new BehaviorSubject(null);
  private subjectOpenComposeEmailAddModal = new BehaviorSubject(null);
  private subjectOpenComposeEmailEditModal = new BehaviorSubject(null);
  private subjectDealStageVerifyDetailsModal = new BehaviorSubject(null);
  private subjectOpenedClosedActivitiesCount = new BehaviorSubject(null);

  constructor() {}

  //Lead Compose Email
  setsubjectOpenComposeEmailAddModal(string: string) {
    this.subjectOpenComposeEmailAddModal.next(string);
  }

  getsubjectOpenComposeEmailAddModal(): Observable<any> {
    return this.subjectOpenComposeEmailAddModal.asObservable();
  }

  setSubjectEmailTableChanged(flag: boolean) {
    return this.subjectEmailTableChanged.next(flag);
  }

  getSubjectEmailTableChanged(): Observable<any> {
    return this.subjectEmailTableChanged.asObservable();
  }

  setSubjectDraftTableChanged(flag: boolean) {
    return this.subjectDraftTableChanged.next(flag);
  }

  getSubjectDraftTableChanged(): Observable<any> {
    return this.subjectDraftTableChanged.asObservable();
  }

  setsubjectOpenComposeEmailEditModal(string: string) {
    this.subjectOpenComposeEmailEditModal.next(string);
  }

  getsubjectOpenComposeEmailEditModal(): Observable<any> {
    return this.subjectOpenComposeEmailEditModal.asObservable();
  }

  setSubjectOpenScheduleModal(obj: ComposeEmail) {
    this.subjectOpenScheduleModal.next(obj);
  }

  getSubjectOpenScheduleModal(): Observable<any> {
    return this.subjectOpenScheduleModal.asObservable();
  }

  setSubjectScheduledTableChanged(flag: boolean) {
    return this.subjectScheduledTableChanged.next(flag);
  }

  getSubjectScheduledTableChanged(): Observable<any> {
    return this.subjectScheduledTableChanged.asObservable();
  }

  setSubjectNumberOfMails(number: number) {
    return this.subjectNumberOfMail.next(number);
  }

  getSubjectNumberOfMails(): Observable<any> {
    return this.subjectNumberOfMail.asObservable();
  }

  setSubjectNumberOfDraftMails(number: number) {
    return this.subjectNumberOfDraftMail.next(number);
  }

  getSubjectNumberOfDraftMails(): Observable<any> {
    return this.subjectNumberOfDraftMail.asObservable();
  }

  setSubjectNumberOfScheduleMails(number: number) {
    return this.subjectNumberOfScheduleMail.next(number);
  }

  getSubjectNumberOfScheduleMails(): Observable<any> {
    return this.subjectNumberOfScheduleMail.asObservable();
  }

  setSubjectCurrentDealMail(mail: string[]) {
    return this.subjectCurrentDealMail.next(mail);
  }
  getSubjectCurrentDealMail(): Observable<any> {
    return this.subjectCurrentDealMail.asObservable();
  }

  setSubjectRemoveEmailDraftModal(flag: boolean) {
    this.subjectRemoveEmailDraftModal.next(flag);
  }
  getSubjectRemoveEmailDraftModal(): Observable<any> {
    return this.subjectRemoveEmailDraftModal.asObservable();
  }

  //Lead View - open ativities - Task
  setsubjectOpenTaskAddModal(string: string) {
    this.subjectOpenTaskAddModal.next(string);
  }
  getsubjectOpenTaskAddModal(): Observable<any> {
    return this.subjectOpenTaskAddModal.asObservable();
  }

  //Lead View - open ativities - Meeting
  setsubjectOpenMeetingAddModal(string: string) {
    this.subjectOpenMeetingAddModal.next(string);
  }
  getsubjectOpenMeetingAddModal(): Observable<any> {
    return this.subjectOpenMeetingAddModal.asObservable();
  }

  //Lead View - open ativities - Calls
  setsubjectOpenMeetingCallsModal(string: string) {
    this.subjectOpenCallsAddModal.next(string);
  }
  getsubjectOpenMeetingCallsModal(): Observable<string> {
    return this.subjectOpenCallsAddModal.asObservable();
  }

  // Lead Open Activity List view
  getsubjectOpenTaskTableChange(): Observable<any> {
    return this.subjectOpenTaskTableChange.asObservable();
  }
  setsubjectOpenTaskTableChange(flag: boolean) {
    this.subjectOpenTaskTableChange.next(flag);
  }

  // Lead Open Activity Meeting List view
  getsubjectOpenMeetingTableChange(): Observable<any> {
    return this.subjectOpenMeetingTableChange.asObservable();
  }
  setsubjectOpenMeetingTableChange(flag: boolean) {
    this.subjectOpenMeetingTableChange.next(flag);
  }

  //call log
  setSubjectCallLogTableChange(flag: boolean) {
    return this.subjectCallLogTableChange.next(flag);
  }

  getSubjectCallLogTableChange(): Observable<boolean> {
    return this.subjectCallLogTableChange.asObservable();
  }

  //deal stage verify details
  setSubjectDealStageVerifyDetailsModal(str: string) {
    return this.subjectDealStageVerifyDetailsModal.next(str);
  }

  getSubjectDealStageVerifyDetailsModal(): Observable<any> {
    return this.subjectDealStageVerifyDetailsModal.asObservable();
  }

  setSubjectDealStageVerify(flag: boolean) {
    return this.subjectDealStageVerify.next(flag);
  }

  getSubjectDealStageVerify(): Observable<any> {
    return this.subjectDealStageVerify.asObservable();
  }

  setSubjectCancelEmailTableChanged(flag: boolean) {
    return this.subjectCancelEmailTableChanged.next(flag);
  }

  getSubjectCancelEmailTableChanged(): Observable<any> {
    return this.subjectCancelEmailTableChanged.asObservable();
  }

  setSubjectNumberOfCanceledMails(count: number) {
    return this.subjectNumberOfCanceledMails.next(count);
  }

  getSubjectNumberOfCanceledMails(): Observable<any> {
    return this.subjectNumberOfCanceledMails.asObservable();
  }

  // Opened Closed Activities Count
  setSubjectOpendClosedActivitiesCountChanged(
    count: OpenedClosedActivitiesCount
  ) {
    this.subjectOpenedClosedActivitiesCount.next(count);
  }

  getSubjectOpendClosedActivitiesCountChanged(): Observable<any> {
    return this.subjectOpenedClosedActivitiesCount.asObservable();
  }
}
