import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { ResetPassword } from '../models/common-data.model';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  private apiResetPasswordUrl = 'api/UserInformation/resetPassword';

  constructor(private baseDataService: BaseDataService) {}

  resetPassword(resetPasswordReq: ResetPassword): Observable<boolean> {
    return this.baseDataService.makePostCall(
      this.apiResetPasswordUrl,
      resetPasswordReq
    );
  }
}
