import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { DealAssignmentService } from 'src/app/shared/services/deal-assignment.service';
import { DealAssignmentSharedService } from 'src/app/shared/services/deal-assignment-shared.service';
import {
  NotAssignedDeals,
  NotAssignedDealsPaginated,
} from 'src/app/shared/models/deal-assignment.model';

@Component({
  selector: 'app-assign-existing-deals',
  templateUrl: './assign-existing-deals.component.html',
  styleUrls: ['./assign-existing-deals.component.sass'],
})
export class AssignExistingDealsComponent {
  pageSize = 10;
  modalRef: BsModalRef;
  pageIndex: number = 0;
  searchForm: FormGroup;
  pageLength: number = 0;
  totalItems: number = 0;
  @Input() module: string;
  searchText: string = '';
  isLoading: boolean = false;
  dealsIdList: string[] = [];
  baseFilterVm: BaseFilterVm;
  isAssigning: boolean = false;
  @Input() referenceId: string;
  notAssignedDeals: NotAssignedDeals[];
  assignExistingDealsOpenSubject: Subscription;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  @ViewChild('template', { static: true }) template: any;
  displayedColumns: string[] = [
    'Checkbox',
    'DealName',
    'Amount',
    'Stage',
    'ClosingDate',
    'AccountName',
    'ContactName',
    'DealOwner',
  ];

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private helpersService: HelpersService,
    private dealAssignmentService: DealAssignmentService,
    private dealAssignmentSharedService: DealAssignmentSharedService
  ) {
    this.assignExistingDealsOpenSubject = dealAssignmentSharedService
      .getSubjectAssignExistingDealsOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.pageIndex = 0;
          this.searchText = '';
          this.dealsIdList = [];
          this.searchForm.get('Search').setValue('');
          this.dataSource = new MatTableDataSource<NotAssignedDeals>([]);
          this.showModal();
          this.getAllNotAssignedDeals();
        }
      });
  }

  ngOnInit(): void {
    this.getAllNotAssignedDeals();
    this.createForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onSearchChange(searchText: any) {
    this.searchText = searchText;
    this.getAllNotAssignedDeals();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  createForm() {
    this.searchForm = this.fb.group({
      Search: [''],
    });
  }

  onCheckboxChange(event: any, campaignId: string) {
    if (event.checked) {
      this.dealsIdList.push(campaignId);
    } else {
      let index = this.dealsIdList.indexOf(campaignId);
      if (index > -1) {
        this.dealsIdList.splice(index, 1);
      }
    }
  }

  handleCancel() {
    this.modalRef.hide();
    this.dealAssignmentSharedService.setSubjectAssignExistingDealsOpenModal(
      null
    );
    this.dealAssignmentSharedService.setSubjectAssignExistingDealsTableChanged(
      null
    );
  }

  ngOnDestroy(): void {
    this.dealAssignmentSharedService.setSubjectAssignExistingDealsOpenModal(
      null
    );
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  assignDeals() {
    this.isAssigning = true;

    this.dealAssignmentService
      .assignMultipleDeals(this.referenceId, this.module, this.dealsIdList)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: any) => {
          if (res) {
            this.dealAssignmentSharedService.setSubjectAssignExistingDealsTableChanged(
              true
            );
            this.helpersService.showSuccessToastWithMessage('COMMON.ASSIGNED_SUCCESSFULLY');
            this.handleCancel();
          }
          this.isAssigning = false;
        },
        (err) => {
          this.isAssigning = false;
        }
      );
  }

  getAllNotAssignedDeals() {
    this.isLoading = true;

    this.baseFilterVm = {
      searchText: this.searchText,
      isDescending: false,
      sortBy: '',
      groupBy: '',
      filters: [],
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };

    this.dealAssignmentService
      .getAllNotAssignedDeals(this.module, this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: NotAssignedDealsPaginated) => {
          if (res.items) {
            this.notAssignedDeals = res.items;
            this.dataSource = new MatTableDataSource<NotAssignedDeals>(
              this.notAssignedDeals
            );
          }
          this.totalItems = res.totalItems;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllNotAssignedDeals();
  }
}
