import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import {
  CompanyProfile,
  CompanyProfileGroupedList,
  CompanyProfilePaginated,
} from '../models/company-profile.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyProfileService {
  private apiCompanyProfileUrl = 'api/CompanyProfile';

  constructor(public baseDataService: BaseDataService) {}

  createCompanyProfile(companyProfile: CompanyProfile): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCompanyProfileUrl}`,
      companyProfile
    );
  }

  getAllCompanyProfiles(
    baseFilterVm: BaseFilterVm
  ): Observable<CompanyProfilePaginated | CompanyProfileGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiCompanyProfileUrl}/getAllCompanyProfiles`,
      baseFilterVm
    );
  }

  deleteCompanyProfile(id: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiCompanyProfileUrl}/${id}`
    );
  }

  getCompanyProfiletById(id: string): Observable<CompanyProfile> {
    return this.baseDataService.makeGetCall(
      `${this.apiCompanyProfileUrl}/${id}`
    );
  }

  editCompanyProfile(companyProfile: CompanyProfile): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiCompanyProfileUrl}`,
      companyProfile
    );
  }

  cloneCompanyProfile(companyId: string): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCompanyProfileUrl}/clone?companyProfileId=${companyId}`
    );
  }

  getProfileId(): Observable<any> {
    return this.baseDataService.makeGetCall(
      `${this.apiCompanyProfileUrl}/getProfileId`
    );
  }

  checkCompanyAvailability(id: string, isCRMAdmin: boolean): Observable<boolean> {
    return this.baseDataService.makeGetCall(
      `${this.apiCompanyProfileUrl}/checkCompanyAvailable?companyProfileId=${id}&isCRMAdmin=${isCRMAdmin}`
    );
  }

}
